import { apiService } from "../store.service";
import type { PostQueryParametersModel } from "../../components/common/Pagination/Pagination.types";
import type { ReportQuestionnaireSectionSimpleResponseModel } from "./reportQuestionSections.types";

import { getQuestionnaireSectionsConfig } from "./reportQuestionSections.config";

export const ReportQuestionSectionsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getQuestionnaireSections: build.query<ReportQuestionnaireSectionSimpleResponseModel[], { headerId: number } & { params?: PostQueryParametersModel }>({
      query: ({ headerId, params }) => ({
        ...getQuestionnaireSectionsConfig(headerId),
        params,
      }),
    }),
  }),
});

export const {
  useGetQuestionnaireSectionsQuery,
} = ReportQuestionSectionsApi