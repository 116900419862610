/* eslint-disable react/no-multi-comp */
import type { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { RouteConfig } from "../routes.config";
import type { AnyModuleProps, ExactAllProps, ExactOneOfProps } from "./AccessControl.types";

import { useAccessControl } from "./AccessControl.hooks";

const AnyModule: FC<AnyModuleProps> = ({ module }) => {
  const { hasAnyModule } = useAccessControl();
  return hasAnyModule(module) ? <Outlet /> : <Navigate to={RouteConfig.DASHBOARD.fullPath} replace />
}

const ExactOneOf: FC<ExactOneOfProps> = ({ modules }) => {
  const { hasExactOneOf } = useAccessControl();
  return hasExactOneOf(modules) ? <Outlet /> : <Navigate to={RouteConfig.DASHBOARD.fullPath} replace />
}

const ExactAll: FC<ExactAllProps> = ({ modules }) => {
  const { hasExactAll } = useAccessControl();
  return hasExactAll(modules) ? <Outlet /> : <Navigate to={RouteConfig.DASHBOARD.fullPath} replace />
}

export const AccessControl = {
  AnyModule,
  ExactOneOf,
  ExactAll,
}