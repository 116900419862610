import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useSelectDataLoaded, useSelectToken, useSelectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../Routes/routes.config";
import { Loader } from "../../common/Loader/Loader.component";

export const PrivateRoutesController = () => {
  const userData = useSelectUserData()
  const accessToken = useSelectToken()
  const userDataLoaded = useSelectDataLoaded()
  const hasAccess = useMemo(() => accessToken && userDataLoaded && !userData.forcePasswordChange, [userData]);

  if (accessToken && userData.forcePasswordChange) {
    return <Navigate to={RouteConfig.NEW_PASSWORD.fullPath} replace />
  }

  if (accessToken && !userDataLoaded) {
    return <Loader fullPage />
  }

  return hasAccess ? <Outlet /> : <Navigate to={RouteConfig.LOGIN.fullPath} replace />
}