import { useMemo } from "react";
import type { TFunction } from "i18next";

import { useSelectUserData } from "../../../store/auth/auth.selectors";
import { useAccessControl } from "../../App/Routes/permissions/AccessControl.hooks";
import { AppModules } from "../../App/Routes/permissions/availablePermissions";
import { RouteConfig } from "../../App/Routes/routes.config";
import type { MenuItemsConfigType } from "./MenuList.types";

export const useMenuConfig = (t: TFunction): MenuItemsConfigType[] => {
  const userData = useSelectUserData()
  const { hasAnyModule, hasExactOneOf } = useAccessControl()
  const isAdmin = userData.roleName === "Admin"

  const menuItems = useMemo(() => [
    {
      text: t("common:menu:overview"),
      iconName: "home",
      navigateTo: RouteConfig.DASHBOARD.fullPath,
      available: true,
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:emissionReport"),
      iconName: "pie-chart",
      navigateTo: RouteConfig.EXPLORER.fullPath,
      available: true,
      disabled: false,
      visible: true
    },
    {
      text: t("common:menu:inputData"),
      iconName: "map",
      navigateTo: RouteConfig.LOCATIONS.fullPath.replace("/:locationId?", ""),
      available: hasExactOneOf([AppModules.SCOPE_1, AppModules.SCOPE_2]),
      disabled: !hasExactOneOf([AppModules.SCOPE_1, AppModules.SCOPE_2]),
      visible: true,
    },
    {
      text: t("common:menu:valueChainEmissions"),
      iconName: "chain",
      navigateTo: RouteConfig.VALUE_CHAIN.fullPath.replace("/:inputFormId?", ""),
      available: hasAnyModule(AppModules.SCOPE_3),
      disabled: !hasAnyModule(AppModules.SCOPE_3),
      visible: true
    },
    {
      text: t("common:menu:reports"),
      iconName: "file",
      navigateTo: RouteConfig.REPORTS.fullPath.replace("/:reportId?", ""),
      available: hasAnyModule(AppModules.REPORTS),
      disabled: !hasAnyModule(AppModules.REPORTS),
      visible: true
    },
    {
      text: t("common:menu:scenarios"),
      iconName: "tools",
      navigateTo: RouteConfig.SCENARIOS.fullPath.replace("/:scenarioId?", ""),
      available: hasAnyModule(AppModules.SCENARIOS),
      disabled: !hasAnyModule(AppModules.SCENARIOS),
      visible: true
    },
    {
      text: t("common:menu:offsettingMarketplace"),
      iconName: "company",
      navigateTo: "/*",
      available: true,
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:sustainabilityMarketplace"),
      iconName: "coins",
      navigateTo: "/*",
      available: true,
      disabled: true,
      visible: true
    },
    {
      text: t("common:menu:knowledgeBase"),
      iconName: "knowledge",
      navigateTo: RouteConfig.KNOWLEDGE_BASE.fullPath,
      available: hasAnyModule(AppModules.KNOWLEDGE),
      disabled: !hasAnyModule(AppModules.KNOWLEDGE),
      visible: true
    },
    {
      text: t("common:menu:settings"),
      iconName: "settings",
      navigateTo: RouteConfig.SETTINGS.fullPath,
      available: true,
      disabled: false,
      visible: isAdmin
    },
  ], [t])

  return menuItems
}
