import type { FC } from "react";

import { useAppSelector } from "../../../../../../hooks/storeHooks";
import { useGetQuestionnaireAnswersQuery } from "../../../../../../store/reportAnswers/reportAnswers.api";
import { useGetQuestionnaireSectionsQuery } from "../../../../../../store/reportQuestionSections/reportQuestionSections.api";
import { selectQuestionnaireCurrentPage } from "../../../../../../store/reports/reports.selectors";
import { Loader } from "../../../../../../components/common/Loader/Loader.component";
import { QuestionnairePage } from "./QuestionnairePage/QuestionnairePage.component";

export type QuestionnaireStepProps = {
  headerId: number
}

export const QuestionnaireStep: FC<QuestionnaireStepProps> = ({ headerId }) => {
  const currentPage = useAppSelector(selectQuestionnaireCurrentPage)

  const {
    data: sections,
    isFetching: sectionsLoading,
  } = useGetQuestionnaireSectionsQuery(
    {
      headerId,
      params: { PageNumber: currentPage }
    },
    { skip: !currentPage }
  )

  const {
    data: defaultValues,
    isLoading: defaultValuesLoading,
  } = useGetQuestionnaireAnswersQuery(
    {
      headerId,
      params: { PageNumber: currentPage }
    },
    { skip: !currentPage }
  )

  if (sectionsLoading || defaultValuesLoading) {
    return <Loader />
  }

  return (
    <>
      {sections && defaultValues ? (
        <QuestionnairePage
          headerId={headerId}
          sections={sections}
          defaultValues={defaultValues}
        />
      ) : null}
    </>
  )
}