import type { TFunction } from "i18next";

import type { CheckboxGroupOption } from "../../../../../../../../components/common/CheckboxController/CheckboxController.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import { QuestionType } from "./QuestionnaireQuestion.types";

export const validateQuestion = (value: CheckboxGroupOption[] | string, question: ReportQuestionSimpleResponseModel, t: TFunction) => {
  if (typeof value === "string" && question.isRequired && value.trim().length === 0) {
    return t("errors:validation:requiredField");
  }
  if (typeof value === "string" && question.isRequired && question.min && value.length < question.min) {
    return t("errors:validation:minChars", { count: question.min });
  }
  if (typeof value === "string" && question.max && value.length > question.max) {
    return t("errors:validation:maxChars", { count: question.max });
  }

  if (typeof value === "string" && (question.type === QuestionType.INTEGER || question.type === QuestionType.DECIMAL) && !!question.max && Number(value) > question.max) {
    return t("errors:validation:maxValue", { count: question.max });
  }

  if (typeof value === "string" && (question.type === QuestionType.INTEGER || question.type === QuestionType.DECIMAL) && !!question.min && Number(value) < question.min) {
    return t("errors:validation:minValue", { count: question.min });
  }

  if (question.type === QuestionType.MS_TICKBOXES && !question.isRequired && !value.length) {
    return t("errors:validation:requiredField");
  }

  return null;
};