import { apiService } from "../store.service";
import type { ReportResponseModel, ReportSimpleResponseModel } from "./reports.types";

import { getReportByIdConfig, getReportsConfig } from "./reports.config";

export const ReportsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getReports: build.query<ReportResponseModel[], { search?: string }>({
      query: ({ search }) => ({
        ...getReportsConfig,
        params: {
          search
        }
      }),
    }),
    getReportById: build.query<ReportSimpleResponseModel, number>({
      query: (reportId) => ({
        ...getReportByIdConfig(reportId),
      }),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useLazyGetReportsQuery,
  useGetReportByIdQuery,
  useLazyGetReportByIdQuery,
} = ReportsApi