import { useEffect, useMemo } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import dayjs from "dayjs";
import i18next from "i18next";
import("dayjs/locale/pl")

import { Outlet } from "react-router-dom";

import { useAppDispatch } from "../../../hooks/storeHooks";
import { useLazyLogInByTokenQuery } from "../../../store/auth/auth.api";
import { useSelectDataLoaded, useSelectToken, useSelectUserData } from "../../../store/auth/auth.selectors";
import { setAccessToken } from "../../../store/auth/auth.slice";
import { useGetAllAvailableLanguagesQuery } from "../../../store/languages/languages.api";
import { Loader } from "../../common/Loader/Loader.component";
import { UnsupportedDevice } from "../../UnsupportedDevice/UnsupportedDevice.component";
import type { GetAllAvailableLanguagesResponseType } from "../../../store/languages/languages.types";

export const SessionController = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"))

  const userData = useSelectUserData()
  const accessToken = useSelectToken()
  const userDataLoaded = useSelectDataLoaded()
  const dispatch = useAppDispatch()

  const hasAccess = useMemo(() => accessToken && userDataLoaded && !userData.forcePasswordChange, [userData])
  const [logInByToken, { isLoading: loginLoading }] = useLazyLogInByTokenQuery()
  const { data, isLoading } = useGetAllAvailableLanguagesQuery()

  useEffect(() => {
    if (data && hasAccess && accessToken) {
      const langCode = data.find((lang: GetAllAvailableLanguagesResponseType) => lang.languageId === userData.languageId)
      i18next.changeLanguage(langCode?.code ?? "en")
      dayjs.locale(langCode?.code ?? "en")
    }
  }, [data, userData])

  useEffect(() => {
    const token = localStorage.getItem("token") ?? null;
    if (token) {
      dispatch(setAccessToken(token))
      logInByToken()
    }
  }, [])

  if (isLoading || loginLoading) {
    return <Loader size="x-large" fullPage />
  }

  if (isMobile) {
    return <UnsupportedDevice />;
  }

  return <Outlet />
}