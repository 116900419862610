import type { ChangeEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";
import { HttpStatusCode } from "axios";
import { useSnackbar } from "notistack";

import { useAddQuestionnaireAnswerMutation, useUpdateQuestionnaireAnswerMutation } from "../../../../../../../../store/reportAnswers/reportAnswers.api";
import type { ReportAnswerSimpleResponseModel } from "../../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import type { ApiErrorResponseModel } from "../../../../../../../../store/store.types";

export const useQuestionnaireRadioGroup = (
  headerId: number,
  question: ReportQuestionSimpleResponseModel,
  questionNumber: string,
  defaultValue?: ReportAnswerSimpleResponseModel,
) => {
  const { t } = useTranslation()

  const prevValueRef = useRef<string | null>(null);
  const [localData, setLocalData] = useState<string | null>(defaultValue?.values[0] ?? null);
  const [conflictDetected, setConflictDetected] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const [postAnswer, { isLoading: postLoading }] = useAddQuestionnaireAnswerMutation()
  const [updateAnswer, { isLoading: updateLoading }] = useUpdateQuestionnaireAnswerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleSave = async (newValue: string) => {
    try {
      if (defaultValue) {
        await updateAnswer({
          headerId,
          data: {
            values: [newValue],
            reportAnswerId: defaultValue.reportAnswerId,
          },
        }).unwrap()
      } else {
        await postAnswer({
          data: {
            values: [newValue],
            reportQuestionId: question.reportQuestionId,
            reportHeaderId: headerId,
          }
        }).unwrap()
      }
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
      } else {
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    setLocalData(newValue);
    handleSave(newValue)
    if (error) setError(null);
  };

  useEffect(() => {
    if (defaultValue?.values[0] !== prevValueRef.current) {
      setLocalData(defaultValue?.values[0] ?? null)
      if (localData !== defaultValue?.values[0] && !!localData && !conflictDetected) {
        enqueueSnackbar(t("reports:questionnaireStep:questionHasBeenUpdated", { questionNumber }), { variant: "warning" })
      }

      prevValueRef.current = defaultValue?.values[0] ?? null;
    }
  }, [defaultValue?.values[0]]);

  return {
    localData,
    handleChange,
    isLoading: postLoading || updateLoading
  }
}