import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { List, Paper, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { useGetReportHeaderByIdQuery } from "../../../../store/reportHeaders/reportHeaders.api";
import { selectStepperCurrentStep, selectStepperSteps } from "../../../../store/reports/reports.selectors";
import { setStep } from "../../../../store/reports/reports.slice";
import { SubmenuReportsStepperItem } from "./SubmenuReportsStepperItem/SubmenuReportsStepperItem.component";

export type SubmenuReportsStepperProps = {
  reportTitle: string
}

export const SubmenuReportsStepper: FC<SubmenuReportsStepperProps> = ({ reportTitle }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const currentStep = useAppSelector(selectStepperCurrentStep)
  const steps = useAppSelector(selectStepperSteps)
  const [searchParams] = useSearchParams()

  const headerId = searchParams.get("headerId")

  const { isError } = useGetReportHeaderByIdQuery(
    { headerId: Number(headerId) },
    {
      skip: !headerId,
      refetchOnMountOrArgChange: true
    })

  return (
    <>
      <Typography
        variant="h2"
        mb={5.5}
        lineHeight="40px"
      >
        {reportTitle}
      </Typography>

      <Paper>
        <Typography
          variant="body1"
          fontSize="18px"
          fontWeight={500}
          color="text.dark"
        >
          {t("reports:createReport")}
        </Typography>

        <List>
          {steps.map((step, index) => (
            <SubmenuReportsStepperItem
              key={step.title}
              step={step}
              onClick={() => dispatch(setStep(index))}
              disabled={!headerId && index !== 0 || isError && index !== 0}
              active={currentStep === index}
              stepNumber={index + 1}
              headerId={Number(headerId)}
            />
          ))}
        </List>
      </Paper>
    </>
  );
}