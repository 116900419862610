import { useSelectAvailableModules } from "../../../../store/auth/auth.selectors";

import type { AppModules } from "./availablePermissions";

export const useAccessControl = () => {
  const availableModules = useSelectAvailableModules()

  return {
    hasAnyModule: (module: AppModules) => availableModules.includes(module),
    hasExactOneOf: (modules: AppModules[]) => modules.some(module => availableModules.includes(module)),
    hasExactAll: (modules: AppModules[]) => modules.every(module => availableModules.includes(module)),
  }
}