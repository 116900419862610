import type { RootState } from "../store.types"

import type { ReportsState } from "./reports.slice"

export const selectStepperCurrentStep: ((state: RootState) => ReportsState["stepper"]["currentStep"]) = ({ reports }) => reports.stepper.currentStep

export const selectStepperSteps: ((state: RootState) => ReportsState["stepper"]["steps"]) = ({ reports }) => reports.stepper.steps

export const selectQuestionnaireCurrentPage: ((state: RootState) => ReportsState["questionnairePages"]["currentPage"]) = ({ reports }) => reports.questionnairePages.currentPage

export const selectQuestionnairePages: ((state: RootState) => ReportsState["questionnairePages"]["pages"]) = ({ reports }) => reports.questionnairePages.pages