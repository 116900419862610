import type { TFunction } from "i18next";

import { CreateReportSteps } from "./FormStepper.types";

export const stepsConfig = (t: TFunction, questionnaireType?: string) => ([
  {
    title: t("reports:stepper:timePeriodTitle"),
    enabled: true,
    component: CreateReportSteps.TIME_PERIOD,
  },
  {
    title: "Questionnaire",
    enabled: questionnaireType === "CSRD",
    component: CreateReportSteps.QUESTIONNAIRE,
  },
  {
    title: t("reports:stepper:generalInformationTitle"),
    enabled: questionnaireType === "GHG",
    component: CreateReportSteps.GENERAL_INFO,
  },
  {
    title: t("reports:stepper:detailsTitle"),
    enabled: true,
    component: CreateReportSteps.DETAILS,
  },
  {
    title: t("reports:stepper:authorTitle"),
    enabled: true,
    component: CreateReportSteps.AUTHOR,
  },
  {
    title: t("reports:stepper:locationsTitle"),
    enabled: questionnaireType === "GHG",
    component: CreateReportSteps.FACILITIES,
  },
  {
    title: t("reports:stepper:reviewTitle"),
    enabled: true,
    component: CreateReportSteps.REVIEW,
  },
])