import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useSelectDataLoaded, useSelectToken, useSelectUserData } from "../../../store/auth/auth.selectors";
import { RouteConfig } from "../Routes/routes.config";

export const PublicRoutesController = () => {
  const userData = useSelectUserData()
  const accessToken = useSelectToken()
  const userDataLoaded = useSelectDataLoaded()

  const hasAccess = useMemo(() => accessToken && userDataLoaded && !userData.forcePasswordChange, [userData])

  return hasAccess ? <Navigate to={RouteConfig.DASHBOARD.fullPath} replace /> : <Outlet />
}