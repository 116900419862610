import { useState } from "react";
import { useTranslation } from "react-i18next";
import { matchRoutes, Route, Routes, useLocation } from "react-router-dom";
import { Box, ListItemText, useMediaQuery, useTheme } from "@mui/material";

import logo from "../../assets/img/logo.svg"
import { AccessControl } from "../App/Routes/permissions/AccessControl";
import { AppModules } from "../App/Routes/permissions/availablePermissions";
import SvgIcon from "../common/SvgIcon/SvgIcon.component";
import { MenuList } from "./MenuList/MenuList.component";
import { SubmenuExplorer } from "./SubmenuExplorer/SubmenuExplorer.component";
import { SubmenuKnowledgeBase } from "./SubmenuKnowledgeBase/SubmenuKnowledgeBase.component";
import { SubmenuLocations } from "./SubmenuLocations/SubmenuLocations.component";
import { SubmenuReports } from "./SubmenuReports/SubmenuReports.component";
import { SubmenuSettings } from "./SubmenuSettings/SubmenuSettings.component";
import { SubmenuTools } from "./SubmenuTools/SubmenuTools.component";
import { SubmenuValueChain } from "./SubmenuValueChain/SubmenuValueChain.component";
import { SubmenuWrapper } from "./SubmenuWrapper/SubmenuWrapper.component";
import { EnabledSubmenuPaths } from "./AppMenuSidebar.types";
import { StyledDrawer, StyledLogoWrapper, StyledSidebarWrapper } from "./AppMenuSidebar.styles";
import { StyledListItemIcon, StyledMenuItem } from "./MenuList/MenuListItem/MenuListItem.styles";

const submenuRoutes = [
  { path: EnabledSubmenuPaths.EXPLORER },
  { path: EnabledSubmenuPaths.LOCATIONS },
  { path: EnabledSubmenuPaths.SETTINGS },
  { path: EnabledSubmenuPaths.TOOLS },
  { path: EnabledSubmenuPaths.REPORTS },
  { path: EnabledSubmenuPaths.VALUE_CHAIN },
  { path: EnabledSubmenuPaths.KNOWLEDGE_BASE },
]

export const AppMenuSidebar = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isLgDown = useMediaQuery(theme.breakpoints.down("lg"))
  const menuExpanded = localStorage.getItem("menuExpanded") === "true"
  const [open, setOpen] = useState<boolean>(menuExpanded);
  const location = useLocation()
  const matches = matchRoutes(submenuRoutes, location)

  const handleDrawerOpen = () => {
    setOpen(true);
    localStorage.setItem("menuExpanded", "true")
  };

  const handleDrawerClose = () => {
    setOpen(false);
    localStorage.setItem("menuExpanded", "false")
  };

  return (
    <StyledSidebarWrapper submenuVisible={!!matches}>
      <StyledDrawer
        variant="permanent"
        open={isLgDown ? false : open}
      >

        <StyledLogoWrapper>
          <img src={logo} alt="redigo" />
        </StyledLogoWrapper>

        <MenuList open={open} />

        <Box mt="auto">
          <StyledMenuItem
            onClick={() => open ? handleDrawerClose() : handleDrawerOpen()}
            disabled={isLgDown}
          >
            <StyledListItemIcon open={open}>
              <SvgIcon name="menu-expand" />
            </StyledListItemIcon>

            <ListItemText
              primary={t("common:collapseMenu")}
              sx={{ ml: 1.5, whiteSpace: "normal" }}
            />
          </StyledMenuItem>
        </Box>
      </StyledDrawer>

      <Routes>
        <Route element={<SubmenuWrapper />}>
          <Route
            path={EnabledSubmenuPaths.EXPLORER}
            element={<SubmenuExplorer />}
          />

          <Route element={<AccessControl.ExactOneOf modules={[AppModules.SCOPE_1, AppModules.SCOPE_2]} />}>
            <Route
              path={EnabledSubmenuPaths.LOCATIONS}
              element={<SubmenuLocations />} />
          </Route>

          <Route element={<AccessControl.AnyModule module={AppModules.SCOPE_3} />}>
            <Route
              path={EnabledSubmenuPaths.VALUE_CHAIN}
              element={<SubmenuValueChain />}
            />
          </Route>

          <Route element={<AccessControl.AnyModule module={AppModules.REPORTS} />}>
            <Route
              path={EnabledSubmenuPaths.REPORTS}
              element={<SubmenuReports />}
            />
          </Route>

          <Route element={<AccessControl.AnyModule module={AppModules.SCENARIOS} />}>
            <Route
              path={EnabledSubmenuPaths.TOOLS}
              element={<SubmenuTools />}
            />
          </Route>

          <Route element={<AccessControl.AnyModule module={AppModules.KNOWLEDGE} />}>
            <Route
              path={EnabledSubmenuPaths.KNOWLEDGE_BASE}
              element={<SubmenuKnowledgeBase />}
            />
          </Route>

          <Route
            path={EnabledSubmenuPaths.SETTINGS}
            element={<SubmenuSettings />}
          />

        </Route>
      </Routes>
    </StyledSidebarWrapper>
  );
}