import type { FC } from "react";
import { useEffect, useState } from "react";
import { Box, Collapse, Typography } from "@mui/material";

import type { QuestionnaireQuestionProps } from "./QuestionnaireQuestion.types";

import { createQuestion, createSubtext } from "./QuestionnaireQuestion.config";

export const QuestionnaireQuestion: FC<QuestionnaireQuestionProps> = ({ headerId, question, sectionNumber, parentQuestionNumber, defaultValues }) => {
  const questionNumber = `${sectionNumber}.${parentQuestionNumber ? `${parentQuestionNumber}.` : ""}${question.number}.`
  const [visible, setVisible] = useState(!question.dependencies)

  useEffect(() => {
    const relatedFieldValue = defaultValues?.data.find(value => value.reportQuestionId === question.dependencies?.[0].dependentOnQuestionId)

    setVisible(question.dependencies?.[0]?.choiceValue === relatedFieldValue?.values[0])
  }, [defaultValues, question.dependencies])

  return (
    <Collapse in={visible}>
      <Box mb={2} ml={parentQuestionNumber ? 2 : 0}>
        {question.title ? (
          <Box display="flex">
            <Typography
              variant="body3"
              mb={0.5}
              textTransform="uppercase"
            >
              {question.title}
            </Typography>
          </Box>
        ) : null}

        {question.text ? (
          <Box display="flex">
            <Typography
              variant="body1"
              mb={2}
            >
              {sectionNumber}.
              {parentQuestionNumber ? `${parentQuestionNumber}.` : null}
              {question.number}.&nbsp;
              {question.text}
            </Typography>
          </Box>
        ) : null}

        {createQuestion(headerId, question, questionNumber, defaultValues)}

        {question.subtext ? question.subtext.map(subtext => createSubtext(subtext)) : null}

        {question.subquestions ? question.subquestions.map(subquestion => (
          <QuestionnaireQuestion
            key={subquestion.reportQuestionId}
            headerId={headerId}
            question={subquestion}
            sectionNumber={sectionNumber}
            parentQuestionNumber={question.number}
            defaultValues={defaultValues}
          />
        )) : null}
      </Box>
    </Collapse>
  )
}