import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Link, Typography } from "@mui/material";

import { useModal } from "../../hooks/useModal";
import { PageWrapper } from "../../layouts/PageWrapper";
import { useSelectUserData } from "../../store/auth/auth.selectors";
import { useGetAllLocationsQuery } from "../../store/locations/locations.api";
import { useGetWidgetSelectionsQuery } from "../../store/widgetSelections/widgetSelections.api";
import { Alert } from "../../components/common/Alert/Alert.component";
import { Loader } from "../../components/common/Loader/Loader.component";
import { ContactFormModal } from "../../components/ContactFormModal/ContactFormModal.component";
import { OverviewHeader } from "./OverviewHeader/OverviewHeader.component";
import { OverviewIntro } from "./OverviewIntro/OverviewIntro.component";
import { renderWidget } from "./OverviewPage.utils";

export const OverviewPage: FC = () => {
  const { t } = useTranslation()
  const userData = useSelectUserData()
  const contactModal = useModal()
  const {
    data: locations,
    isFetching: locationsFetching
  } = useGetAllLocationsQuery({ addOptionAll: true }, { refetchOnMountOrArgChange: true })

  const {
    data: widgetSelections,
    isFetching: widgetSelectionsLoading,
  } = useGetWidgetSelectionsQuery()

  const widgets = useMemo(() => widgetSelections?.filter(widget => widget.show).sort((a, b) => a.displayOrder - b.displayOrder), [widgetSelections])

  if (locationsFetching) {
    return <Loader />
  }

  return (
    <PageWrapper>
      {userData.isTrial ? (
        <Alert
          icon={false}
          title=""
          severity="success"
          sx={{ mb: 2 }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            color="grey.700"
          >
            {t("common:trialInfo", { count: userData.trialDaysRemaining ?? 0 })}&nbsp;
            <Link onClick={contactModal.open}>
              {t("common:contactUs")}
            </Link>
          </Typography>
        </Alert>
      ) : null}
      <OverviewHeader />

      {locations?.length
        ? widgetSelectionsLoading
          ? <Loader />
          : (
            <Grid container spacing={3}>
              {widgets?.map(widget => renderWidget(widget))}
            </Grid>
          )
        : <OverviewIntro />
      }

      <ContactFormModal
        open={contactModal.isOpen}
        onClose={contactModal.close}
      />
    </PageWrapper>
  )
}