import type { FC, SyntheticEvent } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../hooks/storeHooks";
import { selectFuelConsumptions, selectRenewableConsumptions, selectUtilityConsumptions } from "../../../../store/consumptions/consumptions.selectors";
import { setFuelEditMode, setRenewableEditMode, setUtilityEditMode } from "../../../../store/consumptions/consumptions.slice";
import { useAccessControl } from "../../../App/Routes/permissions/AccessControl.hooks";
import { AppModules } from "../../../App/Routes/permissions/availablePermissions";
import { TabPanel } from "../../../common/TabPanel/TabPanel.component";
import { FeatureNotAvailable } from "../../../FeatureNotAvailable/FeatureNotAvailable.component";
import { LocationFuelsForm } from "./Fuels/LocationFuelsForm/LocationFuelsForm.component";
import { LocationFuelsTable } from "./Fuels/LocationFuelsTable/LocationFuelsTable.component";
import { LocationRenewablesForm } from "./Renewables/LocationRenewablesForm/LocationRenewablesForm.component";
import { LocationRenewablesTable } from "./Renewables/LocationRenewablesTable/LocationRenewablesTable.component";
import { LocationUtilitiesForm } from "./Utilities/LocationUtilitiesForm/LocationUtilitiesForm.component";
import { LocationUtilitiesTable } from "./Utilities/LocationUtilitiesTable/LocationUtilitiesTable.component";
import { filterFuelsRecords } from "./Fuels/LocationFuelsTable/LocationFuelsTable.utils";
import { filterRenewablesRecords } from "./Renewables/LocationRenewablesTable/LocationRenewablesTable.utils";
import { filterUtilitiesRecords } from "./Utilities/LocationUtilitiesTable/LocationUtilitiesTable.utils";
import { StyledTab, StyledTabs } from "./LocationWorkspace.styles";

type LocationWorkspaceProps = {
  locationId: number
}

export const LocationWorkspace: FC<LocationWorkspaceProps> = ({ locationId }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(0);
  const dispatch = useAppDispatch()
  const { hasAnyModule } = useAccessControl()

  const recentFuels = useAppSelector(selectFuelConsumptions)
  const recentFuelsPerLocation = filterFuelsRecords(recentFuels, locationId)

  const recentUtilities = useAppSelector(selectUtilityConsumptions)
  const recentUtilitiesPerLocation = filterUtilitiesRecords(recentUtilities, locationId)

  const recentRenewables = useAppSelector(selectRenewableConsumptions)
  const recentRenewablesPerLocation = filterRenewablesRecords(recentRenewables, locationId)

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setFuelEditMode(false))
    dispatch(setUtilityEditMode(false))
    dispatch(setRenewableEditMode(false))
    setValue(newValue);
  };

  return (
    <Box>
      <StyledTabs
        value={value}
        onChange={handleChange}
      >
        <StyledTab label={t("locations:fuels")} />
        <StyledTab label={t("locations:utilities")} />
        <StyledTab label={t("locations:renewable")} />
      </StyledTabs>

      <TabPanel value={value} index={0}>
        <LocationFuelsTable
          locationId={locationId}
          fuels={recentFuelsPerLocation}
          showEmpty={false}
        />
        {hasAnyModule(AppModules.SCOPE_1)
          ? <LocationFuelsForm locationId={locationId} />
          : <FeatureNotAvailable />}
      </TabPanel>

      <TabPanel value={value} index={1}>
        <LocationUtilitiesTable
          locationId={locationId}
          utilities={recentUtilitiesPerLocation}
          showEmpty={false}
        />
        {hasAnyModule(AppModules.SCOPE_2)
          ? <LocationUtilitiesForm locationId={locationId} />
          : <FeatureNotAvailable />}
      </TabPanel>

      <TabPanel value={value} index={2}>
        <LocationRenewablesTable
          locationId={locationId}
          renewables={recentRenewablesPerLocation}
          showEmpty={false}
        />
        <LocationRenewablesForm locationId={locationId} />
      </TabPanel>
    </Box>
  );
}