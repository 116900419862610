import { LuInfo } from "react-icons/lu";
import { Box, Tooltip, Typography } from "@mui/material";

import type { CheckboxGroupOption } from "../../../../../../../../components/common/CheckboxGroup/CheckboxGroup.component";
import type { ReportQuestionMultiChoiceSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";

export const initialCheckboxOptions = (parsedChoices: CheckboxGroupOption[], selectedValues: string[]) =>
  parsedChoices.filter((choice) => selectedValues.includes(choice.value.toString()));

export const parseChoices = (choices: ReportQuestionMultiChoiceSimpleResponseModel[]) => choices.map(choice => ({
  value: choice.value,
  label: (
    <Box display="flex" alignItems="center">
      <Typography variant="body1">
        {choice.displayString}
      </Typography>

      {choice.tooltip ? (
        <Tooltip
          key={choice.tooltip}
          title={choice.tooltip}
          arrow
          placement="right"
        >
          <Box display="flex" alignItems="center" ml={0.5}>
            <LuInfo size={13} />
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  )
}))