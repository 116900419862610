import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { Button } from "../../../components/common/Button/Button.component";
import { Loader } from "../../../components/common/Loader/Loader.component";
import type { ReportSimpleResponseModel } from "../../../store/reports/reports.types";

export type CreateReportTabProps = {
  report?: ReportSimpleResponseModel
  loading: boolean
  isError: boolean
}

export const CreateReportTab: FC<CreateReportTabProps> = ({ report, loading, isError }) => {
  const { t } = useTranslation()

  if (loading) {
    return <Loader />
  }

  return (
    <Grid
      spacing={3}
      container
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Paper>
          {isError
            ? t("errors:globalError")
            : (
              <>
                <Typography
                  variant="h1"
                  color="text.dark"
                  fontWeight={500}
                >
                  {report?.name}
                </Typography>

                {report?.description ? (
                  <Typography
                    variant="body1"
                    color="text.dark"
                    mt={1}
                  >
                    {report.description}
                  </Typography>
                ) : null}

                <Box sx={{ mt: 3 }}>
                  <Button
                    size="small"
                    component={NavLink}
                    to="create"
                    sx={{ mr: 3 }}
                    disabled={!report?.isAvailable}
                  >
                    {t("common:getStarted")}
                  </Button>
                </Box>
              </>
            )}
        </Paper>
      </Grid>
    </Grid>
  )
}
