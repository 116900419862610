import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit"

import type { CreateReportSteps } from "../../pages/Reports/CreateReportPage/FormStepper/FormStepper.types";

export type StepConfig = {
  title: string
  enabled: boolean
  component: CreateReportSteps
}

export type ReportsState = {
  stepper: {
    currentStep: number
    steps: StepConfig[]
  },
  questionnairePages: {
    currentPage: number
    // TODO: set types when backend will be ready
    pages: {
      id: number
      name: string
    }[]
  }
}

const initialState: ReportsState = {
  stepper: {
    currentStep: 0,
    steps: [],
  },
  questionnairePages: {
    currentPage: 0,
    pages: []
  }
}

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    nextStep: (state) => {
      if (state.stepper.currentStep < state.stepper.steps.length - 1) {
        state.stepper.currentStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.stepper.currentStep > 0) {
        state.stepper.currentStep -= 1;
      }
    },
    setStep: (state, { payload }: PayloadAction<number>) => {
      state.stepper.currentStep = payload;
    },
    setSteps: (state, { payload }: PayloadAction<ReportsState["stepper"]["steps"]>) => {
      state.stepper.steps = payload;
    },

    // Report questionnaire stepper
    nextPage: (state) => {
      if (state.questionnairePages.currentPage < state.questionnairePages.pages.length - 1) {
        state.questionnairePages.currentPage += 1;
      }
    },
    prevPage: (state) => {
      if (state.questionnairePages.currentPage > 0) {
        state.questionnairePages.currentPage -= 1;
      }
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.questionnairePages.currentPage = payload;
    },
    setPages: (state, { payload }: PayloadAction<ReportsState["questionnairePages"]["pages"]>) => {
      state.questionnairePages.pages = payload;
    },
    // e/o Report questionnaire stepper
    clearSession: () =>
      ({ ...initialState })
    ,
  },
})

export const {
  nextStep,
  prevStep,
  setStep,
  setSteps,

  nextPage,
  prevPage,
  setPage,
  setPages,
} = reportsSlice.actions

export default reportsSlice.reducer