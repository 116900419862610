import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, MenuList as MuiMenuList } from "@mui/material";
import Divider from "@mui/material/Divider";

import { useModal } from "../../../hooks/useModal";
import { ContactFormModal } from "../../ContactFormModal/ContactFormModal.component";
import { MenuListItem } from "./MenuListItem/MenuListItem.component";

import { useMenuConfig } from "./MenuList.config";

export const MenuList: FC<{ open: boolean }> = ({ open }) => {
  const { t } = useTranslation()
  const menuItems = useMenuConfig(t)
  const contactModal = useModal()

  return (
    <MuiMenuList>
      {menuItems.map(({ text, iconName, navigateTo, disabled, available, visible }, index) => {
        const getTooltipText = () => {
          switch (true) {
            case open && !available: return (
              <>
                <Box
                  component={Link}
                  color="primary.300"
                  onClick={contactModal.open}
                >
                  {t("common:upgrade")}
                </Box>&nbsp;
                {t("common:toAccess")}
              </>
            )
            case !open && !available: return (
              <>
                <Box
                  component={Link}
                  color="primary.300"
                  onClick={contactModal.open}
                >
                  {t("common:upgrade")}
                </Box>&nbsp;
                {t("common:toAccess")} {text}
              </>
            )
            case (open && disabled): return t("common:comingSoon")
            case (!open && disabled): return `${text} - ${t("common:comingSoon")}`
            default: return text
          }
        }

        return (
          <div key={text}>
            {index === menuItems.length - 1 && <Divider sx={{ my: 1 }} />}
            {visible ? (
              <MenuListItem
                key={text}
                text={text}
                iconName={iconName}
                navigateTo={navigateTo}
                tooltipText={open && disabled || !open ? getTooltipText() : null}
                disabled={disabled}
                available={available}
              />
            ) : null}
          </div>
        )
      })}

      <ContactFormModal
        open={contactModal.isOpen}
        onClose={contactModal.close}
      />
    </MuiMenuList>

  );
}