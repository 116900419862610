import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import type { AxiosError } from "axios";

import { useDeleteQuestionFileMutation, usePostQuestionFileMutation, useUpdateQuestionFileMutation } from "../../../../../../../../store/reportAnswers/reportAnswers.api";
import { Button } from "../../../../../../../../components/common/Button/Button.component";
import { InfoBox } from "../../../../../../../../components/common/InfoBox/InfoBox.component";
import { Loader } from "../../../../../../../../components/common/Loader/Loader.component";
import FileDropzone from "../../../../../../../../components/LocationsModal/ImportData/UploadTemplateSection/FileDropzone/FileDropzone.component";
import type { ReportAnswerSimpleResponseModel } from "../../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import type { ApiErrorResponseModel } from "../../../../../../../../store/store.types";

export type QuestionnaireUploadImageProps = {
  headerId: number
  question: ReportQuestionSimpleResponseModel
  defaultValue?: ReportAnswerSimpleResponseModel
  lastModified: string
  questionNumber: string
}

export const QuestionnaireUploadImage: FC<QuestionnaireUploadImageProps> = ({ headerId, question, defaultValue }) => {
  const { t } = useTranslation()
  const [apiErrors, setApiErrors] = useState<string[]>([])
  const [validationErrors, setValidationErrors] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const [updateFile] = useUpdateQuestionFileMutation()
  const [postFile] = usePostQuestionFileMutation()
  const [deleteFile] = useDeleteQuestionFileMutation()

  const handleFileChange = async (files: File[]) => {
    setSuccess(false)
    setLoading(true)

    if (files.length) {
      const formData = new FormData()
      formData.append("file", files[0])

      try {
        if (defaultValue) {
          await updateFile({
            headerId,
            reportAnswerId: defaultValue.reportAnswerId,
            data: formData,
            onProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted)
              }
            },
          })
        } else {
          await postFile({
            headerId,
            reportQuestionId: question.reportQuestionId,
            data: formData,
            onProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setProgress(percentCompleted)
              }
            },
          })
        }

        setSuccess(true)
      } catch (err) {
        const e = err as AxiosError<ApiErrorResponseModel>
        setSuccess(false)
        setLoading(false)
        if (e.response?.data.errors?.file) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const errors: string[] = e.response.data.errors.file.map((error: string) => error)
          setApiErrors(errors)
        } else if (e.response?.data.detail) {
          setApiErrors([e.response.data.detail])
        } else {
          setApiErrors([t("errors:globalError")])
        }
      }
    }
    setLoading(false)
  }

  const handleDelete = async () => {
    try {
      await deleteFile({ headerId, reportAnswerId: defaultValue?.reportAnswerId as number })
      setSuccess(false)
      setProgress(0)
    } catch (e) {
      // TODO: handle error
    }
  }

  return (
    <Box>
      {defaultValue ? (
        <InfoBox
          mb={2}
          type="info"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <Typography>
              {t("reports:questionnaireStep:alreadyUpdatedFile", { fileName: defaultValue.values[0] })}
            </Typography>

            <Button
              variant="outlined"
              size="tiny"
              sx={{ marginLeft: "auto", minWidth: "unset" }}
              onClick={handleDelete}
            >
              {t("common:remove")}
            </Button>
          </Box>
        </InfoBox>
      ) : null}

      <FileDropzone
        apiErrors={apiErrors}
        validationErrors={validationErrors}
        resetApiErrors={() => {
          setValidationErrors(false)
          setApiErrors([])
          setProgress(0)
        }}
        handleInputChange={handleFileChange}
        percent={progress}
        success={success}
        acceptedFileTypes={{
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
        }}
      />
      {success ? (
        <InfoBox
          mt={2}
          type="success"
        >
          {t("reports:questionnaireStep:dataSuccessfullyAdded")}
        </InfoBox>
      ) : null}

      {loading ? (
        <Box my={2}><Loader withoutText /></Box>
      ) : null}
    </Box>
  )
}