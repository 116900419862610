import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";
import { HttpStatusCode } from "axios";
import { useSnackbar } from "notistack";

import { deepEqual, useAutoSave } from "../../../../../../../../hooks/useAutoSave";
import { useAddQuestionnaireAnswerMutation, useDeleteQuestionnaireAnswerMutation, useUpdateQuestionnaireAnswerMutation } from "../../../../../../../../store/reportAnswers/reportAnswers.api";
import { validateQuestion } from "../../QuestionnairePage/QuestionnaireQuestion/QuestionnaireQuestion.validation";
import type { CheckboxGroupOption } from "../../../../../../../../components/common/CheckboxGroup/CheckboxGroup.component";
import { initialCheckboxOptions, parseChoices } from "./QuestionnaireCheckboxGroup.utils";
import type { ReportAnswerSimpleResponseModel } from "../../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import type { ApiErrorResponseModel } from "../../../../../../../../store/store.types";

export const useQuestionnaireCheckboxGroup = (
  headerId: number,
  question: ReportQuestionSimpleResponseModel,
  lastModified: string,
  questionNumber: string,
  defaultValue?: ReportAnswerSimpleResponseModel,
) => {
  const parsedChoices = useMemo(() => parseChoices(question.choices ?? []), [question.choices]);
  const { t } = useTranslation()

  const [localData, setLocalData] = useState<CheckboxGroupOption[]>([]);
  const [conflictDetected, setConflictDetected] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const [postAnswer] = useAddQuestionnaireAnswerMutation()
  const [updateAnswer, { isLoading: updateLoading }] = useUpdateQuestionnaireAnswerMutation()
  const [deleteAnswer] = useDeleteQuestionnaireAnswerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const initialValue = useMemo(
    () => initialCheckboxOptions(parsedChoices, defaultValue?.values ?? []),
    [parsedChoices, defaultValue?.values]
  );

  const handleSave = async (selectedValues: CheckboxGroupOption[]) => {
    const validationError = validateQuestion(selectedValues, question, t)

    if (validationError) {
      setError(validationError)
      return
    }

    try {
      if (!selectedValues.length) {
        await deleteAnswer({
          headerId,
          reportAnswerId: defaultValue?.reportAnswerId as number,
          lastModified
        }).unwrap()
      } else {
        if (defaultValue) {
          await updateAnswer({
            headerId,
            lastModified,
            data: {
              values: selectedValues.map(data => data.value.toString()),
              reportAnswerId: defaultValue.reportAnswerId,
            },
          }).unwrap()
        } else {
          await postAnswer({
            data: {
              values: selectedValues.map(data => data.value.toString()),
              reportQuestionId: question.reportQuestionId,
              reportHeaderId: headerId,
            }
          }).unwrap()
        }
      }
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
      } else {
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  const { value, onChange, isSaving } = useAutoSave<CheckboxGroupOption[]>({
    value: initialValue,
    onSave: handleSave,
  });

  const handleChange = (selectedOptions: CheckboxGroupOption[]) => {
    setLocalData(selectedOptions);
    onChange(selectedOptions);
    if (error) setError(null);
  };

  const handleSync = () => {
    setLocalData(initialValue);
    onChange(initialValue)
    setConflictDetected(false)
  }

  const handleOverwrite = async () => {
    try {
      if (!localData.length) {
        await deleteAnswer({
          headerId,
          reportAnswerId: defaultValue?.reportAnswerId as number,
          lastModified
        }).unwrap()
      } else {
        if (defaultValue) {
          await updateAnswer({
            headerId,
            lastModified,
            data: {
              values: localData.map(data => data.value.toString()),
              reportAnswerId: defaultValue.reportAnswerId,
            },
          }).unwrap()
        } else {
          await postAnswer({
            data: {
              values: localData.map(data => data.value.toString()),
              reportQuestionId: question.reportQuestionId,
              reportHeaderId: headerId,
            }
          }).unwrap()
        }
      }

      setConflictDetected(false);
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
        enqueueSnackbar(t("errors:alreadyUpdated"), { variant: "warning" })
      } else {
        setConflictDetected(false);
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  useEffect(() => {
    onChange(initialValue)

    if (defaultValue?.values && !!localData.length && !conflictDetected) {
      const hasChanged = !deepEqual(localData, defaultValue.values.map(val =>
        parsedChoices.find(choice => choice.value === val)
      ));

      if (hasChanged) {
        setLocalData(initialValue)
        enqueueSnackbar(t("reports:questionnaireStep:questionHasBeenUpdated", { questionNumber }), { variant: "warning" });
      }
    }
  }, [defaultValue?.values]);

  return {
    parsedChoices,
    value,
    handleChange,
    isSaving,
    error,
    conflictDetected,
    localData,
    handleSync,
    handleOverwrite,
    updateLoading,
  }
}