import type { ChangeEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";
import { HttpStatusCode } from "axios";
import { useSnackbar } from "notistack";

import { useAutoSaveOnBlur } from "../../../../../../../../hooks/useAutoSave";
import { useAddQuestionnaireAnswerMutation, useDeleteQuestionnaireAnswerMutation, useUpdateQuestionnaireAnswerMutation } from "../../../../../../../../store/reportAnswers/reportAnswers.api";
import { validateQuestion } from "../../QuestionnairePage/QuestionnaireQuestion/QuestionnaireQuestion.validation";
import type { ReportAnswerSimpleResponseModel } from "../../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import type { ApiErrorResponseModel } from "../../../../../../../../store/store.types";
import { QuestionType } from "../../QuestionnairePage/QuestionnaireQuestion/QuestionnaireQuestion.types";

export const useQuestionnaireNumberField = (
  headerId: number,
  question: ReportQuestionSimpleResponseModel,
  lastModified: string,
  questionNumber: string,
  defaultValue?: ReportAnswerSimpleResponseModel,
) => {
  const { t } = useTranslation()

  const prevValueRef = useRef<string | null>(null);
  const [localData, setLocalData] = useState<string | null>(null);
  const [conflictDetected, setConflictDetected] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const [postAnswer] = useAddQuestionnaireAnswerMutation()
  const [updateAnswer, { isLoading: updateLoading }] = useUpdateQuestionnaireAnswerMutation()
  const [deleteAnswer] = useDeleteQuestionnaireAnswerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleSave = async (newValue: string) => {
    const validationError = validateQuestion(newValue, question, t)

    if (validationError) {
      setError(validationError)
      return
    }

    try {
      if (!localData?.trim()) {
        await deleteAnswer({
          headerId,
          reportAnswerId: defaultValue?.reportAnswerId as number,
          lastModified
        }).unwrap()
      } else {
        if (defaultValue) {
          await updateAnswer({
            headerId,
            lastModified,
            data: {
              values: [newValue],
              reportAnswerId: defaultValue.reportAnswerId,
            },
          }).unwrap()
        } else {
          await postAnswer({
            data: {
              values: [newValue],
              reportQuestionId: question.reportQuestionId,
              reportHeaderId: headerId,
            }
          }).unwrap()
        }
      }
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
      } else {
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  const { value, onChange, onBlur, isSaving } = useAutoSaveOnBlur({
    value: defaultValue?.values[0] ?? "",
    onSave: handleSave,
  });

  const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const newValue = ev.target.value
    const isIntegerField = question.type === QuestionType.INTEGER
    const isInteger = /^\d*$/u.test(newValue)

    if (isIntegerField) {
      if (isInteger) {
        setLocalData(newValue);
        onChange(newValue);
        if (error) setError(null);
      }
    } else {
      setLocalData(newValue);
      onChange(newValue);
      if (error) setError(null);
    }
  }

  const handleSync = () => {
    setLocalData(defaultValue?.values[0] ?? null);
    onChange(defaultValue?.values[0] ?? "")
    setConflictDetected(false)
  }

  const handleOverwrite = async () => {
    try {
      if (!localData?.trim()) {
        await deleteAnswer({
          headerId,
          reportAnswerId: defaultValue?.reportAnswerId as number,
          lastModified
        }).unwrap()
      } else {
        await updateAnswer({
          headerId,
          lastModified,
          data: {
            values: [localData],
            reportAnswerId: defaultValue?.reportAnswerId as number,
          }
        }).unwrap()
      }

      setConflictDetected(false);
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
        enqueueSnackbar(t("errors:alreadyUpdated"), { variant: "warning" })
      } else {
        setConflictDetected(false);
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  useEffect(() => {
    if (defaultValue?.values[0] !== prevValueRef.current) {
      if (localData !== defaultValue?.values[0] && !!localData && !conflictDetected) {
        enqueueSnackbar(t("reports:questionnaireStep:questionHasBeenUpdated", { questionNumber }), { variant: "warning" })
      }

      prevValueRef.current = defaultValue?.values[0] ?? null;
    }
  }, [defaultValue?.values[0]]);

  return {
    value,
    handleChange,
    onBlur,
    isSaving,
    error,
    conflictDetected,
    localData,
    handleSync,
    handleOverwrite,
    updateLoading,
  }
}