import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Dialog, Typography } from "@mui/material";

import { Button } from "../../../../../../../../components/common/Button/Button.component";
import type { CheckboxGroupOption } from "../../../../../../../../components/common/CheckboxGroup/CheckboxGroup.component";

export type OverrideDataConfirmationModalProps = {
  open: boolean
  serverData: string[] | string | null
  localData: CheckboxGroupOption[] | string | null
  handleSync: () => void
  handleOverwrite: () => void
  loading: boolean
}

export const OverrideDataConfirmationModal: FC<OverrideDataConfirmationModalProps> = ({ open, localData, serverData, handleSync, handleOverwrite, loading }) => {
  const { t } = useTranslation()

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
    >
      <Typography
        variant="h3"
        fontWeight={500}
        mb={3}
      >
        {t("reports:questionnaireStep:conflictModalTitle")}
      </Typography>

      <Typography
        variant="body1"
        color="text.secondary"
        mb={3}
      >
        {t("reports:questionnaireStep:conflictModalDescription", {
          serverData: Array.isArray(serverData) ? serverData.join(", ") : serverData,
          localData: Array.isArray(localData) ? localData.map(data => data.value).join(", ") : localData,
        })}
      </Typography>

      <Box
        display="flex"
        justifyContent="space-between"
      >
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          onClick={handleSync}
          disabled={loading}
        >
          {t("common:keep")}
        </Button>

        <Button
          size="small"
          onClick={handleOverwrite}
          disabled={loading}
        >
          {t("common:overwrite")}
        </Button>
      </Box>
    </Dialog>
  );
}