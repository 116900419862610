import { Box, Link, Typography } from "@mui/material"

import { theme } from "../../../../../../../../theme/theme"
import { InfoBox } from "../../../../../../../../components/common/InfoBox/InfoBox.component"
import { QuestionnaireAutocomplete } from "../../components/QuestionnaireAutocomplete/QuestionnaireAutocomplete.component"
import { QuestionnaireCheckboxGroup } from "../../components/QuestionnaireCheckboxGroup/QuestionnaireCheckboxGroup.component"
import { QuestionnaireNumberField } from "../../components/QuestionnaireNumberField/QuestionnaireNumberField.component"
import { QuestionnaireRadioGroup } from "../../components/QuestionnaireRadioGroup/QuestionnaireRadioGroup.component"
import { QuestionnaireTextarea } from "../../components/QuestionnaireTextarea/QuestionnaireTextarea.component"
import { QuestionnaireUploadImage } from "../../components/QuestionnaireUploadImage/QuestionnaireUploadImage.component"
import type { ReportAnswerSimpleResponseModelWithHeaders } from "../../../../../../../../store/reportAnswers/reportAnswers.types"
import type { ReportQuestionSimpleResponseModel, ReportQuestionSubtextSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types"
import { QuestionType, SubtextType } from "./QuestionnaireQuestion.types"

export const createQuestion = (
  headerId: number,
  question: ReportQuestionSimpleResponseModel,
  questionNumber: string,
  defaultValues?: ReportAnswerSimpleResponseModelWithHeaders
) => {
  switch (question.type) {
    case QuestionType.TEXTAREA:
      return (
        <QuestionnaireTextarea
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
          lastModified={defaultValues?.lastModified ?? ""}
        />
      )
    case QuestionType.INTEGER:
    case QuestionType.DECIMAL:
      return (
        <QuestionnaireNumberField
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
          lastModified={defaultValues?.lastModified ?? ""}
        />
      )
    case QuestionType.RADIO:
      return (
        <QuestionnaireRadioGroup
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
        />
      )
    case QuestionType.DROPDOWN:
      return (
        <QuestionnaireAutocomplete
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
          lastModified={defaultValues?.lastModified ?? ""}
        />
      )
    case QuestionType.MS_TICKBOXES:
      return (
        <QuestionnaireCheckboxGroup
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
          lastModified={defaultValues?.lastModified ?? ""}
        />
      )
    case QuestionType.IMAGE:
      return (
        <QuestionnaireUploadImage
          key={question.reportQuestionId}
          questionNumber={questionNumber}
          question={question}
          headerId={headerId}
          defaultValue={defaultValues?.data.find(defaultValue => defaultValue.reportQuestionId === question.reportQuestionId)}
          lastModified={defaultValues?.lastModified ?? ""}
        />
      )
    default:
      break
  }
}

export const createSubtext = (subtext: ReportQuestionSubtextSimpleResponseModel) => {
  switch (subtext.type) {
    case SubtextType.INFORMATION:
      return (
        <Box key={subtext.number} my={2}>
          <InfoBox
            type="info"
            border={`1px solid ${theme.palette.info.main}!important`}
          >
            <Typography
              variant="body1"
              sx={{ whiteSpace: "pre-line" }}
            >
              {subtext.value}
            </Typography>
            {subtext.link && subtext.linkLabel ? (
              <Link
                href={subtext.link}
                target="_blank"
                sx={{
                  color: theme.palette.text.primary,
                  textDecoration: `underline ${theme.palette.text.primary}`,
                  "&:hover": {
                    textDecoration: `underline ${theme.palette.text.primary}`
                  }
                }}
              >
                {subtext.linkLabel}
              </Link>
            ) : null}
          </InfoBox>
        </Box>
      )
    default:
      break
  }
}