import type { FC } from "react";
import { useEffect, useState } from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Box, Collapse, ListItemButton, styled, Typography } from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/storeHooks";
import { useGetQuestionnairePagesQuery } from "../../../../../store/reportQuestionPages/reportQuestionPages.api";
import { selectQuestionnaireCurrentPage } from "../../../../../store/reports/reports.selectors";
import type { StepConfig } from "../../../../../store/reports/reports.slice";
import { setPage } from "../../../../../store/reports/reports.slice";
import { CreateReportSteps } from "../../../../../pages/Reports/CreateReportPage/FormStepper/FormStepper.types";
import { StyledStepNumber } from "./SubmenuReportsStepperItem.styles";

const StyledIndicatorWrapper = styled(
  Box,
  { shouldForwardProp: (prop) => prop !== "collapse" }
)<{ collapse: boolean }>(({ collapse }) => ({
  "& svg": {
    transform: `rotate(${collapse ? 180 : 0}deg)`,
    transition: "0.3s all",
    transformOrigin: "50% 50%",
  }
}))

export type SubmenuReportsStepperItemProps = {
  step: StepConfig;
  onClick: () => void
  disabled: boolean
  active: boolean
  stepNumber: number
  headerId: number
}

export const SubmenuReportsStepperItem: FC<SubmenuReportsStepperItemProps> = ({
  step,
  onClick,
  disabled,
  active,
  stepNumber,
  headerId,
}) => {
  const dispatch = useAppDispatch()
  const [collapse, setCollapse] = useState(false)
  const { data } = useGetQuestionnairePagesQuery({ headerId }, { skip: !headerId || (step.component !== CreateReportSteps.QUESTIONNAIRE && !active) })
  const currentPage = useAppSelector(selectQuestionnaireCurrentPage)

  const handleClick = () => {
    if (step.component === CreateReportSteps.QUESTIONNAIRE) {
      setCollapse(true)
      dispatch(setPage(1))
    }

    onClick()
  }

  useEffect(() => {
    if (!active && step.component === CreateReportSteps.QUESTIONNAIRE) {
      setCollapse(false)
    } else if (active && step.component === CreateReportSteps.QUESTIONNAIRE && !collapse) {
      dispatch(setPage(1))
      setCollapse(true)
    }
  }, [active, currentPage])

  return (
    <>
      <ListItemButton
        sx={{
          py: 0.75,
          px: 0,
          "&:hover": {
            background: "transparent"
          }
        }}
        onClick={handleClick}
        disabled={disabled}
      >
        <StyledStepNumber active={active}>
          <Typography
            variant="body2"
            fontWeight={500}
          >
            {stepNumber}
          </Typography>
        </StyledStepNumber>

        <Typography
          variant="body1"
          ml={1}
          color="text.secondary"
        >
          {step.title}
        </Typography>

        {step.component === CreateReportSteps.QUESTIONNAIRE ? (
          <Box sx={{ ml: "auto" }}>
            <StyledIndicatorWrapper collapse={collapse}>
              <MdOutlineKeyboardArrowDown />
            </StyledIndicatorWrapper>
          </Box>
        ) : null}
      </ListItemButton>

      {step.component === CreateReportSteps.QUESTIONNAIRE ? (
        <Collapse in={collapse}>
          {data?.map(page => (
            <ListItemButton
              key={page.number}
              sx={{
                py: 0.75,
                "&:hover": {
                  background: "transparent"
                }
              }}
              onClick={() => dispatch(setPage(page.number))}
              disabled={disabled}
            >
              <Typography
                variant="body1"
                ml={1}
                color="text.secondary"
                fontWeight={page.number === currentPage ? 700 : 400}
              >
                {page.title}
              </Typography>
            </ListItemButton>
          ))}
        </Collapse>
      ) : null}
    </>
  )
}