import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { LuInfo } from "react-icons/lu";
import { Box, FormControlLabel, FormHelperText, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";

import { InfoLabel } from "../../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import type { QuestionnaireRadioGroupProps } from "./QuestionnaireRadioGroup.types";

import { useQuestionnaireRadioGroup } from "./QuestionnaireRadioGroup.hooks";

export const QuestionnaireRadioGroup: FC<QuestionnaireRadioGroupProps> = ({ headerId, question, defaultValue, questionNumber }) => {
  const { t } = useTranslation()
  const {
    localData,
    handleChange,
    isLoading,
  } = useQuestionnaireRadioGroup(headerId, question, questionNumber, defaultValue)

  return (
    <Box mb={2}>
      <RadioGroup
        value={localData}
        onChange={handleChange}
      >
        {question.choices?.map((choice) => (
          <FormControlLabel
            key={choice.value}
            value={choice.value}
            control={<Radio size="small" />}
            disabled={isLoading}
            label={(
              <Box
                display="flex"
                alignItems="center"
              >
                <Typography
                  variant="body1"
                  mr={0.5}
                >
                  {choice.displayString}
                </Typography>

                {choice.tooltip ? (
                  <Tooltip
                    title={choice.tooltip}
                    placement="right"
                  >
                    <span>
                      <LuInfo size={13} />
                    </span>
                  </Tooltip>
                ) : null}
              </Box>
            )}
          />
        ))}
      </RadioGroup>
      {isLoading ? (
        <FormHelperText>
          <InfoLabel>{t("common:saving")}</InfoLabel>
        </FormHelperText>
      ) : null}
    </Box>
  )
}