import type { FC } from "react";
import { useTranslation } from "react-i18next";

import { TextField } from "../../../../../../../../components/common/TextField/TextField.component";
import { OverrideDataConfirmationModal } from "../OverrideDataConfirmationModal/OverrideDataConfirmationModal.component";
import type { QuestionnaireTextareaProps } from "./QuestionnaireTextarea.types";

import { useQuestionnaireTextarea } from "./QuestionnaireTextarea.hooks";

export const QuestionnaireTextarea: FC<QuestionnaireTextareaProps> = ({
  headerId,
  question,
  defaultValue,
  lastModified,
  questionNumber
}) => {
  const { t } = useTranslation()
  const {
    value,
    handleChange,
    onBlur,
    isSaving,
    error,
    conflictDetected,
    localData,
    handleSync,
    handleOverwrite,
    updateLoading,
  } = useQuestionnaireTextarea(headerId, question, lastModified, questionNumber, defaultValue)

  return (
    <>
      <TextField
        placeholder={question.placeholder ?? ""}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        fullWidth
        multiline
        rows={3}
        disabled={isSaving}
        error={!!error}
        helperText={error
          ? error
          : isSaving ? t("common:saving") : null
        }
      />

      <OverrideDataConfirmationModal
        open={conflictDetected}
        localData={localData}
        serverData={defaultValue?.values[0] ?? null}
        handleSync={handleSync}
        handleOverwrite={handleOverwrite}
        loading={updateLoading}
      />
    </>
  )
}