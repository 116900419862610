import type { ComponentOverride } from "../types"

export const MuiRadio: ComponentOverride["MuiRadio"] = {
  variants: [
    {
      props: { size: "small" },
      style: ({ theme }) => ({
        padding: theme.spacing(1, 0.5, 1, 1),
        "& .MuiSvgIcon-root": {
          maxWidth: 16,
          maxHeight: 16,
        },
      }),
    },
    {
      props: { size: "medium" },
      style: ({
        "& .MuiSvgIcon-root": {
          maxWidth: 20,
          maxHeight: 20,
        },
      }),
    },
  ],
}
