import type { FC, ReactNode } from "react"
import { I18nextProvider } from "react-i18next"
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";

import i18n from "../../services/i18n/i18n";
import { store } from "../../store/store";
import { theme } from "../../theme/theme";
import "../../styles/fonts/googlesans/stylesheet.css"

import "@fontsource/caveat"

type AppProviderProps = {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          {children}
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  </I18nextProvider>
)