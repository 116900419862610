import { RouteConfig } from "../../App/Routes/routes.config";
import type { ReportResponseModel } from "../../../store/reports/reports.types";
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getReportsSubmenuItems = (reports: ReportResponseModel[]) => reports.map(
  ({ name, reportId, isAvailable }): SubmenuItemsConfigType => ({
    text: name,
    navigateTo: RouteConfig.REPORTS.fullPath.replace(":reportId?", reportId.toString()),
    disabled: false,
    isAvailable,
  })
)