import { apiService } from "../store.service";
import type { GetAllAvailableLanguagesResponseType } from "./languages.types";

import { getAllAvailableLanguagesConfig } from "./languages.config";

export const LanguagesApi = apiService.injectEndpoints({
  endpoints: build => ({
    getAllAvailableLanguages: build.query<GetAllAvailableLanguagesResponseType[], void>({
      query: () => ({
        ...getAllAvailableLanguagesConfig,
      }),
    })
  }),
});

export const {
  useGetAllAvailableLanguagesQuery,
  useLazyGetAllAvailableLanguagesQuery
} = LanguagesApi