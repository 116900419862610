import type { FC } from "react";
import { Checkbox as MuiCheckbox, FormControlLabel, FormHelperText, Typography } from "@mui/material";

import { InfoLabel } from "../TextField/InfoLabel/InfoLabel.component";
import type { CheckboxProps } from "./Checkbox.types";

export const Checkbox: FC<CheckboxProps> = ({
  label,
  value,
  onChange,
  invalid,
  error,
  disabled,
  labelProps,
}) => (
  <>
    <FormControlLabel
      label={label ? <Typography variant="body1" {...labelProps}>{label}</Typography> : null}
      componentsProps={{ typography: { width: "100%" } }}
      disabled={disabled}
      control={
        <MuiCheckbox checked={value} onChange={onChange} size="small" />
      }
    />

    <FormHelperText>
      <InfoLabel invalid={invalid}>
        {error}
      </InfoLabel>
    </FormHelperText>
  </>
)
