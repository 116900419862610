import type { FC } from "react";
import { Box, Typography } from "@mui/material";

import { QuestionnaireQuestion } from "./QuestionnaireQuestion/QuestionnaireQuestion.component";
import type { ReportAnswerSimpleResponseModelWithHeaders } from "../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionnaireSectionSimpleResponseModel } from "../../../../../../../store/reportQuestionSections/reportQuestionSections.types";

export type QuestionnairePageProps = {
  headerId: number
  sections: ReportQuestionnaireSectionSimpleResponseModel[]
  defaultValues?: ReportAnswerSimpleResponseModelWithHeaders
}

export const QuestionnairePage: FC<QuestionnairePageProps> = ({ headerId, sections, defaultValues }) => (
  <Box maxWidth={600}>
    {sections.map(({ number, title, questions }) => (
      <Box
        key={number}
        mb={2}
      >
        {title ? (
          <Box display="flex" mb={2}>
            <Typography variant="h3">{number}.&nbsp;</Typography>
            <Typography variant="h3">{title}</Typography>
          </Box>
        ) : null}

        {questions.map(question => (
          <QuestionnaireQuestion
            key={question.reportQuestionId}
            headerId={headerId}
            sectionNumber={number}
            question={question}
            defaultValues={defaultValues}
          />
        ))}
      </Box>
    ))}
  </Box>
)