import type { AxiosProgressEvent } from "axios";

import { api } from "../../api/rest/api";
import { apiService } from "../store.service";
import type { PostQueryParametersModel } from "../../components/common/Pagination/Pagination.types";
import type { ReportAnswerPostModel, ReportAnswerPutModel, ReportAnswerSimpleResponseModel, ReportAnswerSimpleResponseModelWithHeaders } from "./reportAnswers.types";

import { addQuestionnaireAnswerConfig, deleteQuestionFileConfig, deleteQuestionnaireAnswerConfig, getQuestionnaireAnswersConfig, postQuestionFileConfig, updateQuestionFileConfig, updateQuestionnaireAnswerConfig } from "./reportAnswers.config";

export const updateFileWithProgress = async (
  headerId: number,
  reportAnswerId: number,
  formData: FormData,
  onProgress: (progressEvent: AxiosProgressEvent) => void
) => api.request({
  ...updateQuestionFileConfig(headerId, reportAnswerId),
  data: formData,
  onUploadProgress: onProgress,
})

export const postFileWithProgress = async (
  headerId: number,
  reportQuestionId: number,
  formData: FormData,
  onProgress: (progressEvent: AxiosProgressEvent) => void
) => api.request({
  ...postQuestionFileConfig(headerId, reportQuestionId),
  data: formData,
  onUploadProgress: onProgress,
})

export const ReportAnswersApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["ReportAnswers"]
  })
  .injectEndpoints({
    endpoints: build => ({
      getQuestionnaireAnswers: build.query<ReportAnswerSimpleResponseModelWithHeaders, {
        headerId: number,
        params?: PostQueryParametersModel,
        lastModified?: string
      }
      >({
        query: ({ headerId, params, lastModified }) => ({
          ...getQuestionnaireAnswersConfig(headerId),
          params,
          headers: {
            "If-Modified-Since": lastModified ?? ""
          }
        }),
        transformResponse: (response: ReportAnswerSimpleResponseModel[], meta: Record<string, never>) => {
          const lastModified = meta.headers["last-modified"];
          return {
            data: response,
            lastModified,
          };
        },
        providesTags: ["ReportAnswers"],
        serializeQueryArgs: ({ endpointName, queryArgs }) => {
          const { headerId, params } = queryArgs;
          return { endpointName, headerId, params };
        },
      }),
      addQuestionnaireAnswer: build.mutation<ReportAnswerSimpleResponseModel, { data: ReportAnswerPostModel }>({
        query: ({ data }) => ({
          ...addQuestionnaireAnswerConfig(data.reportHeaderId),
          data
        }),
        invalidatesTags: ["ReportAnswers"]
      }),
      updateQuestionnaireAnswer: build.mutation<ReportAnswerSimpleResponseModel, { headerId: number, data: ReportAnswerPutModel, lastModified?: string }>({
        query: ({ headerId, data, lastModified }) => ({
          ...updateQuestionnaireAnswerConfig(headerId, data.reportAnswerId),
          data,
          headers: {
            "If-Unmodified-Since": lastModified
          }
        }),
        invalidatesTags: ["ReportAnswers"]
      }),
      deleteQuestionnaireAnswer: build.mutation<ReportAnswerSimpleResponseModel, { headerId: number, reportAnswerId: number, lastModified?: string }>({
        query: ({ headerId, reportAnswerId, lastModified }) => ({
          ...deleteQuestionnaireAnswerConfig(headerId, reportAnswerId),
          headers: {
            "If-Unmodified-Since": lastModified
          }
        }),
        invalidatesTags: ["ReportAnswers"]
      }),

      updateQuestionFile: build.mutation<ReportAnswerSimpleResponseModel, { headerId: number, reportAnswerId: number, data: FormData, onProgress: (progressEvent: AxiosProgressEvent) => void }>({
        queryFn: async ({ headerId, reportAnswerId, data, onProgress }) => {
          try {
            const response = await updateFileWithProgress(headerId, reportAnswerId, data, onProgress)
            return { data: response.data }
          } catch (error) {
            return { error }
          }
        },
        invalidatesTags: ["ReportAnswers"]
      }),
      postQuestionFile: build.mutation<ReportAnswerSimpleResponseModel, { headerId: number, reportQuestionId: number, data: FormData, onProgress: (progressEvent: AxiosProgressEvent) => void }>({
        queryFn: async ({ headerId, reportQuestionId, data, onProgress }) => {
          try {
            const response = await postFileWithProgress(headerId, reportQuestionId, data, onProgress)
            return { data: response.data }
          } catch (error) {
            return { error }
          }
        },
        invalidatesTags: ["ReportAnswers"]
      }),
      deleteQuestionFile: build.mutation<ReportAnswerSimpleResponseModel, { headerId: number, reportAnswerId: number }>({
        query: ({ headerId, reportAnswerId }) => ({
          ...deleteQuestionFileConfig(headerId, reportAnswerId)
        }),
        invalidatesTags: ["ReportAnswers"]
      }),
    }),
  });

export const {
  useGetQuestionnaireAnswersQuery,
  useLazyGetQuestionnaireAnswersQuery,
  useAddQuestionnaireAnswerMutation,
  useUpdateQuestionnaireAnswerMutation,
  useDeleteQuestionnaireAnswerMutation,

  useUpdateQuestionFileMutation,
  usePostQuestionFileMutation,
  useDeleteQuestionFileMutation,
} = ReportAnswersApi