import type { FC } from "react";
import { useEffect, useState } from "react"
import { Box } from "@mui/material";

import { useLazyGetUtilitiesConsumptionsQuery } from "../../../../store/UtilitiesConsumptions/utilitiesConsumptions.api"
import { useAccessControl } from "../../../../components/App/Routes/permissions/AccessControl.hooks";
import { AppModules } from "../../../../components/App/Routes/permissions/availablePermissions";
import { Loader } from "../../../../components/common/Loader/Loader.component"
import { Pagination } from "../../../../components/common/Pagination/Pagination.component";
import { FeatureNotAvailable } from "../../../../components/FeatureNotAvailable/FeatureNotAvailable.component";
import { LocationUtilitiesTable } from "../../../../components/LocationsModal/LocationForms/LocationWorkspace/Utilities/LocationUtilitiesTable/LocationUtilitiesTable.component"

type UtilitiesDataTabProps = {
  locationId: number
}

export const UtilitiesDataTab: FC<UtilitiesDataTabProps> = ({ locationId }) => {
  const { hasAnyModule } = useAccessControl()
  const isModuleAvailable = hasAnyModule(AppModules.SCOPE_2)
  const [getUtilitiesConsumptions, { data, isFetching }] = useLazyGetUtilitiesConsumptionsQuery()
  const utilities = data?.data
  const paginationSettings = data?.meta.pagination ? data.meta.pagination : undefined
  const [page, setPage] = useState(1)

  useEffect(() => {
    if (isModuleAvailable) {
      setPage(1)
      getUtilitiesConsumptions({ locationId })
    }
  }, [locationId])

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
    await getUtilitiesConsumptions({ locationId, pageNumber: newPage })
  }

  useEffect(() => {
    if (paginationSettings?.TotalPages && paginationSettings.TotalPages < page) {
      handleChangePage(undefined, paginationSettings.TotalPages)
    }
  }, [paginationSettings?.TotalPages])

  if (!isModuleAvailable) {
    return <FeatureNotAvailable />
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
    >
      {utilities && paginationSettings && !isFetching ? (
        <LocationUtilitiesTable
          locationId={locationId}
          utilities={utilities}
        />
      ) : <Loader />}

      {paginationSettings?.TotalPages && paginationSettings.TotalPages > 1 ? (
        <Pagination
          page={page}
          count={paginationSettings.TotalPages}
          onChange={handleChangePage}
        />
      ) : null}
    </Box>
  )
}
