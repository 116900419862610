import type { ReportAnswerSimpleResponseModelWithHeaders } from "../../../../../../../../store/reportAnswers/reportAnswers.types"
import type { ReportQuestionnaireSectionSimpleResponseModel, ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types"

export enum QuestionType {
  TEXTAREA = "text",
  INTEGER = "int",
  DECIMAL = "decimal",
  RADIO = "radio",
  DROPDOWN = "dropdown",
  MS_TICKBOXES = "ms_tickboxes",
  IMAGE = "image"
}

export enum SubtextType {
  INFORMATION = "information"
}

export type QuestionnaireQuestionProps = {
  headerId: number
  sectionNumber: ReportQuestionnaireSectionSimpleResponseModel["number"]
  question: ReportQuestionSimpleResponseModel
  parentQuestionNumber?: ReportQuestionSimpleResponseModel["number"]
  defaultValues?: ReportAnswerSimpleResponseModelWithHeaders
}