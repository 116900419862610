import { useAppSelector } from "../../hooks/storeHooks"
import type { RootState } from "../store.types"
import type { AuthState } from "./auth.types"

export const selectToken: ((state: RootState) => AuthState["accessToken"]) = ({ auth }) => auth.accessToken
export const selectUserData: ((state: RootState) => AuthState["userData"]) = ({ auth }) => auth.userData
export const selectDataLoaded: ((state: RootState) => AuthState["dataLoaded"]) = ({ auth }) => auth.dataLoaded
export const selectLanguageId: ((state: RootState) => AuthState["userData"]["languageId"]) = ({ auth }) => auth.userData.languageId
export const selectCompanyName: ((state: RootState) => AuthState["userData"]["companyName"]) = ({ auth }) => auth.userData.companyName
export const selectUserLocale: ((state: RootState) => AuthState["userData"]["locale"]) = ({ auth }) => auth.userData.locale
export const selectAvailableModules: ((state: RootState) => AuthState["userData"]["modules"]) = ({ auth }) => auth.userData.modules

export const useSelectToken = () => useAppSelector(selectToken)
export const useSelectUserData = () => useAppSelector(selectUserData)
export const useSelectDataLoaded = () => useAppSelector(selectDataLoaded)
export const useSelectLanguageId = () => useAppSelector(selectLanguageId)
export const useSelectCompanyName = () => useAppSelector(selectCompanyName)
export const useSelectUserLocale = () => useAppSelector(selectUserLocale)
export const useSelectAvailableModules = () => useAppSelector(selectAvailableModules)
