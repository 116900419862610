import type { FC } from "react";
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import type { AxiosError } from "axios";
import { AxiosHeaders } from "axios";

import { api } from "../../../../api/rest/api"
import { useProcessImportMutation } from "../../../../store/imports/imports.api";
import { getInvalidTemplateConfig, uploadFileConfig } from "../../../../store/imports/imports.config";
import { Button } from "../../../common/Button/Button.component";
import { ButtonLoader } from "../../../common/Button/ButtonLoader/ButtonLoader.component";
import { InfoBox } from "../../../common/InfoBox/InfoBox.component";
import { Loader } from "../../../common/Loader/Loader.component";
import SvgIcon from "../../../common/SvgIcon/SvgIcon.component";
import FileDropzone from "./FileDropzone/FileDropzone.component"
import type { ImportValidationResultResponseModel } from "../../../../store/imports/imports.types";
import type { ApiErrorResponseModel } from "../../../../store/store.types";
import { StyledStepNumber } from "../ImportData.styles"

export type UploadTemplateSectionProps = {
  locationId: number
}

export const UploadTemplateSection: FC<UploadTemplateSectionProps> = ({ locationId }) => {
  const { t } = useTranslation()
  const [apiErrors, setApiErrors] = useState<string[]>([])
  const [validationErrors, setValidationErrors] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [invalidImportId, setInvalidImportId] = useState<number | null>(null)
  const [downloadInvalidTemplateLoading, setDownloadInvalidTemplateLoading] = useState(false)

  const [processImport] = useProcessImportMutation()

  const handleFileChange = async (files: File[]) => {
    setSuccess(false)
    setLoading(true)

    if (files.length) {
      const formData = new FormData()
      formData.append("file", files[0])

      try {
        const { data } = await api.request<ImportValidationResultResponseModel>({
          ...uploadFileConfig(locationId),
          data: formData,
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setProgress(percentCompleted)
            }
          },
        })

        if (data.errorRows === 0) {
          await processImport(data.importId)
          setSuccess(true)
          setValidationErrors(false)
          setInvalidImportId(null)
        } else {
          setValidationErrors(true)
          setInvalidImportId(data.importId)
        }
      } catch (err) {
        const e = err as AxiosError<ApiErrorResponseModel>
        setSuccess(false)
        setLoading(false)
        if (e.response?.data.errors?.file) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const errors: string[] = e.response.data.errors.file.map((error: string) => error)
          setApiErrors(errors)
        } else if (e.response?.data.detail) {
          setApiErrors([e.response.data.detail])
        } else {
          setApiErrors([t("errors:globalError")])
        }
      }
    }
    setLoading(false)
  }

  const handleDownloadInvalidTemplate = async (importId: number) => {
    setDownloadInvalidTemplateLoading(true)
    try {
      const { data, headers } = await api.request<Blob>({
        ...getInvalidTemplateConfig(importId),
      })

      if (headers instanceof AxiosHeaders && headers.has("Content-Disposition")) {
        const contentDispositionHeader = headers.get("Content-Disposition");

        if (typeof contentDispositionHeader === "string") {
          const match = /filename\s*=\s*(?:"([^"]+)"|([^;]+))(?:;|$)/u.exec(contentDispositionHeader);

          if (match) {
            const filename = match[1] || match[2];
            const blob = new Blob([data], {
              type: data.type
            })

            const anchor = document.createElement("a")
            anchor.setAttribute("href", URL.createObjectURL(blob))
            anchor.setAttribute("download", filename)
            document.body.append(anchor)
            anchor.click()
            document.body.removeChild(anchor)
          } else { }
        }
      }

      setDownloadInvalidTemplateLoading(false)
    } catch (e) {
      setDownloadInvalidTemplateLoading(false)
    }
  }

  return (
    <>

      <Box mb={2}>
        <StyledStepNumber>2</StyledStepNumber>
      </Box>

      <Box>
        <Typography
          variant="body1"
          color="text.secondary"
          fontWeight={500}
          mb={2}
        >
          {t("locations:importData:chooseFile")}
        </Typography>

        <FileDropzone
          apiErrors={apiErrors}
          validationErrors={validationErrors}
          resetApiErrors={() => {
            setValidationErrors(false)
            setApiErrors([])
            setProgress(0)
          }}
          handleInputChange={handleFileChange}
          percent={progress}
          success={success}
          acceptedFileTypes={{
            "application/zip": [".xlsx"]
          }}
        />
        {success ? (
          <InfoBox
            mt={2}
            type="success"
          >
            {t("locations:importData:dataSuccessfullyAdded")}
          </InfoBox>
        ) : null}

        {validationErrors ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            <InfoBox
              width="100%"
              my={2}
              type="error"
            >
              {t("locations:importData:downloadAndUploadAgain")}
            </InfoBox>

            <Button
              size="small"
              onClick={async () => invalidImportId && handleDownloadInvalidTemplate(invalidImportId)}
              startIcon={<SvgIcon name="download" />}
              endIcon={downloadInvalidTemplateLoading ? <ButtonLoader /> : null}
              disabled={downloadInvalidTemplateLoading}
            >
              {t("common:downloadFile")}
            </Button>
          </Box>
        ) : null}

        {loading ? (
          <Box my={2}><Loader withoutText /></Box>
        ) : null}
      </Box>
    </>
  );
}