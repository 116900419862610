import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { TextField } from "../../../../../../../../components/common/TextField/TextField.component";
import { OverrideDataConfirmationModal } from "../OverrideDataConfirmationModal/OverrideDataConfirmationModal.component";
import { QuestionType } from "../../QuestionnairePage/QuestionnaireQuestion/QuestionnaireQuestion.types";
import type { QuestionnaireNumberFieldProps } from "./QuestionnaireNumberField.types";
import { StyledUnitTextField } from "./QuestionnaireNumberField.styles";

import { useQuestionnaireNumberField } from "./QuestionnaireNumberField.hooks";

export const QuestionnaireNumberField: FC<QuestionnaireNumberFieldProps> = ({ headerId, question, lastModified, questionNumber, defaultValue }) => {
  const { t } = useTranslation()
  const {
    value,
    handleChange,
    onBlur,
    isSaving,
    error,
    conflictDetected,
    localData,
    handleSync,
    handleOverwrite,
    updateLoading,
  } = useQuestionnaireNumberField(headerId, question, lastModified, questionNumber, defaultValue)

  return (
    <Box mb={2}>
      <TextField
        placeholder={question.placeholder ?? "0"}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        fullWidth
        type={QuestionType.DECIMAL === question.type ? "number" : "text"}
        disabled={isSaving}
        error={!!error}
        helperText={error
          ? error
          : isSaving ? t("common:saving") : null
        }
        InputProps={{
          endAdornment: question.unit ? (
            <StyledUnitTextField
              value={question.unit}
              disabled
            />
          ) : null
        }}
      />

      <OverrideDataConfirmationModal
        open={conflictDetected}
        localData={localData}
        serverData={defaultValue?.values[0] ?? null}
        handleSync={handleSync}
        handleOverwrite={handleOverwrite}
        loading={updateLoading}
      />
    </Box>
  )
}