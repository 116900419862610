import type { FC } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { Box, ListItemText } from "@mui/material";

import { theme } from "../../../../theme/theme";
import SvgIcon from "../../../common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../common/Tooltip/Tooltip.component";
import type { MenuListItemProps } from "./MenuListItem.types";
import { StyledListItemIcon, StyledMenuItem } from "./MenuListItem.styles";

export const MenuListItem: FC<MenuListItemProps> = ({
  text,
  iconName,
  navigateTo,
  disabled,
  tooltipText,
  available,
}) => (
  <Tooltip
    key={text}
    title={tooltipText}
    arrow
    placement="right"
    color={disabled ? "disabled" : "dark"}
  >
    <span>
      <StyledMenuItem
        disabled={!available || disabled}
        component={NavLink}
        to={navigateTo}
      >
        <StyledListItemIcon sx={{ position: "relative" }}>
          <SvgIcon name={iconName} />

          {available ? null : (
            <Box
              position="absolute"
              top="50%"
              left="50%"
            >
              <FaArrowAltCircleUp color={theme.palette.primary.main} size={12} />
            </Box>
          )}
        </StyledListItemIcon>

        <ListItemText
          primary={text}
          sx={{ ml: 1.5 }}
        />

        {available ? null : (
          <Box mr={1.5}>
            <FaArrowAltCircleUp
              color={theme.palette.primary.main}
              size={12}
            />
          </Box>
        )}
      </StyledMenuItem>
    </span>
  </Tooltip>

)