import type { ComponentOverride } from "../types"

import { props } from "./props/props"
import { MuiAlert } from "./alert"
import { MuiButton } from "./buttons"
import { MuiCheckbox } from "./checkbox"
import { MuiChip } from "./chip"
import { MuiCssBaseline } from "./cssBaseline"
import { MuiListItemIcon } from "./icons"
import { input } from "./input"
import { MuiPaper } from "./paper"
import { MuiRadio } from "./radio"
import { table } from "./table"
import { MuiTypography } from "./typography"

export const overrides: ComponentOverride = {
  ...props,
  MuiListItemIcon,
  MuiButton,
  MuiPaper,
  MuiTypography,
  ...input,
  ...table,
  MuiChip,
  MuiCheckbox,
  MuiAlert,
  MuiCssBaseline,
  MuiRadio
}
