import { useCallback, useEffect, useState } from "react";

import debounce from "@mui/utils/debounce";

type UseAutoSaveProps<T> = {
  value: T;
  onSave: (value: T) => Promise<void> | void; // Obsługuje async lub sync
  delay?: number; // Czas debounce w milisekundach
};

export const deepEqual = <T>(a: T, b: T): boolean => {
  if (a === b) return true; // Przypadek dla prymitywów i referencji
  if (typeof a !== typeof b) return false;
  if (a && b && typeof a === "object") {
    const keysA = Object.keys(a) as (keyof T)[];
    const keysB = Object.keys(b) as (keyof T)[];
    if (keysA.length !== keysB.length) return false;
    return keysA.every((key) => deepEqual(a[key], b[key]));
  }
  return false;
};

type WithValue<T> = { value: T };

export const deepEqualByValue = <T>(
  a: T | WithValue<T>[] | undefined,
  b: T | WithValue<T>[] | undefined
): boolean => {
  if (Array.isArray(a) && Array.isArray(b)) {
    // Porównanie dwóch tablic po elementach
    if (a.length !== b.length) return false;

    return a.every((itemA, index) =>
      "value" in itemA && "value" in b[index]
        ? itemA.value === b[index].value
        : false
    );
  }

  if (
    typeof a === "object" &&
    a !== null &&
    typeof b === "object" &&
    b !== null &&
    "value" in a &&
    "value" in b
  ) {
    return a.value === b.value; // Porównanie tylko wartości `value`
  }

  return a === b; // Fallback dla innych przypadków
};

export const useAutoSave = <T,>({
  value,
  onSave,
  delay = 2000,
}: UseAutoSaveProps<T>) => {
  const [internalValue, setInternalValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);

  const debouncedSave = useCallback(
    debounce(async (updatedValue: T) => {
      setIsSaving(true);
      try {
        await onSave(updatedValue);
      } finally {
        setIsSaving(false);
      }
    }, delay),
    [onSave, delay]
  );

  useEffect(() => {
    if (!deepEqualByValue(internalValue, value)) {
      debouncedSave.clear();
      debouncedSave(internalValue);
    }

    return () => {
      debouncedSave.clear();
    };
  }, [internalValue]);

  const handleChange = (newValue: T) => {
    setInternalValue(newValue);
  };

  return {
    value: internalValue,
    onChange: handleChange,
    isSaving,
  };
};

export const useAutoSaveOnBlur = <T>({
  value,
  onSave,
}: UseAutoSaveProps<T>) => {
  const [internalValue, setInternalValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setInternalValue(value);
  }, [value]); // Aktualizacja internalValue przy zmianie value

  const handleChange = (newValue: T) => {
    setInternalValue(newValue);
  };

  const handleBlur = async () => {
    if (internalValue === value) return;

    setIsSaving(true);
    await onSave(internalValue);
    setIsSaving(false);
  };

  return {
    value: internalValue,
    onChange: handleChange,
    onBlur: handleBlur,
    isSaving,
  };
};