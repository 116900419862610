import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, FormHelperText } from "@mui/material";

import { Autocomplete } from "../../../../../../../../components/common/Autocomplete/Autocomplete.component";
import { InfoLabel } from "../../../../../../../../components/common/TextField/InfoLabel/InfoLabel.component";
import { OverrideDataConfirmationModal } from "../OverrideDataConfirmationModal/OverrideDataConfirmationModal.component";
import type { QuestionnaireAutocompleteProps } from "./QuestionnaireAutocomplete.types";

import { useQuestionnaireAutocomplete } from "./QuestionnaireAutocomplete.hooks";

export const QuestionnaireAutocomplete: FC<QuestionnaireAutocompleteProps> = ({ headerId, question, defaultValue, questionNumber, lastModified }) => {
  const { t } = useTranslation()
  const {
    handleSync,
    handleOverwrite,
    conflictDetected,
    parsedChoices,
    localData,
    handleChange,
    isLoading,
  } = useQuestionnaireAutocomplete(headerId, question, lastModified, questionNumber, defaultValue)

  return (
    <Box mb={2}>
      <Autocomplete
        options={parsedChoices ?? []}
        placeholder={question.placeholder ?? ""}
        onChange={async (_, data) => handleChange(data)}
        value={localData ?? undefined}
        disableClearable={true}
        disabled={isLoading}
      />

      {isLoading ? (
        <FormHelperText>
          <InfoLabel>{t("common:saving")}</InfoLabel>
        </FormHelperText>
      ) : null}

      <OverrideDataConfirmationModal
        open={conflictDetected}
        localData={localData?.label ?? ""}
        serverData={defaultValue?.values[0] ?? null}
        handleSync={handleSync}
        handleOverwrite={handleOverwrite}
        loading={isLoading}
      />
    </Box>
  )
}