import type { FC, SyntheticEvent } from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Link, Tabs, Typography } from "@mui/material";

import { useModal } from "../../hooks/useModal";
import { PageWrapper } from "../../layouts/PageWrapper";
import { useGetReportByIdQuery, useGetReportsQuery } from "../../store/reports/reports.api";
import { RouteConfig } from "../../components/App/Routes/routes.config";
import { Alert } from "../../components/common/Alert/Alert.component";
import { Loader } from "../../components/common/Loader/Loader.component";
import { TabPanel } from "../../components/common/TabPanel/TabPanel.component";
import { ContactFormModal } from "../../components/ContactFormModal/ContactFormModal.component";
import { CreateReportTab } from "./CreateReportTab/CreateReportTab.component";
import { SavedReportsTab } from "./SavedReportsTab/SavedReportsTab.component";
import { StyledTab } from "./ReportsPage.styles";

export const ReportsPage: FC = () => {
  const { t } = useTranslation()
  const contactModal = useModal()
  const { reportId } = useParams()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const savedTab = location.state?.savedTab as boolean
  const [currentTab, setCurrentTab] = useState(savedTab ? 1 : 0);

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const { data: reports, isLoading: reportsLoading } = useGetReportsQuery({}, { refetchOnMountOrArgChange: true })

  const incorrectReportId =
    reports
    && reports.length > 0
    && (
      !reportId
      || !reports.find((report) => report.reportId === Number(reportId))
    )

  const {
    data: reportData,
    isLoading: reportLoading,
    isError: reportError
  } = useGetReportByIdQuery(Number(reportId),
    { skip: !reportId || incorrectReportId })

  useEffect(() => {
    if (!reportData?.isAvailable) {
      setCurrentTab(0)
    }
  }, [reportData])

  if (incorrectReportId) {
    const path = RouteConfig.REPORTS.fullPath.replace(":reportId?", reports[0]?.reportId.toString())

    return <Navigate to={path} replace={true} />;
  }

  if (reportsLoading) {
    return <Loader />
  }

  return (
    <PageWrapper>
      {reportData?.isAvailable === false ? (
        <Alert
          icon={false}
          title=""
          severity="success"
        >
          <Typography
            variant="body1"
            fontWeight={500}
            color="grey.700"
          >
            {t("reports:upgradeNeeded")}&nbsp;
            <Link
              onClick={contactModal.open}
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              {t("common:contactUs")}
            </Link>
          </Typography>
        </Alert>
      ) : null}

      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        sx={{ mb: 3 }}
      >
        <StyledTab label={t("reports:newReportTabLabel")} />

        <StyledTab
          label={t("reports:savedReportsTabLabel")}
          disabled={!reportData?.isAvailable}
        />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <CreateReportTab
          report={reportData}
          loading={reportLoading}
          isError={reportError}
        />
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <SavedReportsTab reportId={Number(reportId)} />
      </TabPanel>

      <ContactFormModal
        open={contactModal.isOpen}
        onClose={contactModal.close}
      />
    </PageWrapper>
  )
}
