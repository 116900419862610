import type { FC } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { Link, Tabs, Typography } from "@mui/material";

import { useModal } from "../../../hooks/useModal";
import { PageWrapper } from "../../../layouts/PageWrapper";
import { useGetAllScenariosQuery } from "../../../store/scenarios/scenarios.api";
import { RouteConfig } from "../../../components/App/Routes/routes.config";
import { Alert } from "../../../components/common/Alert/Alert.component";
import { Loader } from "../../../components/common/Loader/Loader.component";
import { ContactFormModal } from "../../../components/ContactFormModal/ContactFormModal.component";
import { NoScenariosView } from "../NoScenariosView/NoScenariosView.component";
import { StyledTab } from "./ScenariosPage.styles";

export const ScenariosPage: FC = () => {
  const { t } = useTranslation()
  const { scenarioId } = useParams()
  const { pathname } = useLocation()
  const contactModal = useModal()

  const { data: scenarios, isLoading: scenariosLoading } = useGetAllScenariosQuery(undefined, { refetchOnMountOrArgChange: true })
  const currentScenario = useMemo(() => scenarios?.find(scenario => scenario.scenarioId === Number(scenarioId)), [scenarios])

  const incorrectScenarioId =
    scenarios
    && scenarios.length > 0
    && (
      !scenarioId
      || !scenarios.find((scenario) => scenario.scenarioId === Number(scenarioId))
    )

  if (incorrectScenarioId) {
    const path = RouteConfig.SCENARIOS.fullPath.replace(":scenarioId?", scenarios[0]?.scenarioId.toString())

    return <Navigate to={path} replace={true} />;
  }

  if (scenariosLoading) {
    return <Loader />
  }

  if (scenarios && scenarios.length === 0) {
    return <NoScenariosView />
  }

  let currentTab = pathname;
  if (pathname.includes("/scenarios/")) {
    currentTab = "/scenarios/";
  }

  if (pathname.includes("/scenarios/") && pathname.includes("/calculator")) {
    currentTab = "/scenarios/";
  }

  if (pathname.includes("/scenarios/") && pathname.includes("/simulations")) {
    currentTab = "/scenarios/simulations";
  }

  return (
    <PageWrapper>
      {currentScenario?.isAvailable === false ? (
        <Alert
          icon={false}
          title=""
          severity="success"
        >
          <Typography
            variant="body1"
            fontWeight={500}
            color="grey.700"
          >
            {t("scenarios:upgradeNeeded")}&nbsp;
            <Link
              onClick={contactModal.open}
              underline="none"
              sx={{ cursor: "pointer" }}
            >
              {t("common:contactUs")}
            </Link>
          </Typography>
        </Alert>
      ) : null}

      <Tabs
        value={currentTab}
        sx={{ mb: 3 }}
      >
        <StyledTab
          label={t("scenarios:scenarioTab")}
          value="/scenarios/"
          component={NavLink}
          to=""
        />
        <StyledTab
          label={t("scenarios:reportsTab")}
          value="/scenarios/simulations"
          component={NavLink}
          to="simulations"
          disabled={currentScenario?.isAvailable === false}
        />
      </Tabs>

      <Outlet context={{ scenarioId: Number(scenarioId) }} />

      <ContactFormModal
        open={contactModal.isOpen}
        onClose={contactModal.close}
      />
    </PageWrapper>
  )
}
