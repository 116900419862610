import { styled } from "@mui/material";

import { TextField } from "../../../../../../../../components/common/TextField/TextField.component";

export const StyledUnitTextField = styled(TextField)(({ theme }) => ({
  maxWidth: 50,
  "& .MuiInputBase-root.Mui-disabled": {
    background: "transparent",
    "& input": {
      WebkitTextFillColor: theme.palette.text.dark,
    },
    "& fieldset": {
      border: "0!important",
    }
  }
}))