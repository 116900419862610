import type { FC } from "react";
import { RiLockLine } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { Box, ListItemText } from "@mui/material";

import SvgIcon from "../../../common/SvgIcon/SvgIcon.component";
import { Tooltip } from "../../../common/Tooltip/Tooltip.component";
import type { SubmenuListItemProps } from "./SubmenuListItem.types";
import { StyledListItemIcon, StyledSubmenuItem } from "./SubmenuListItem.styles";

export const SubmenuListItem: FC<SubmenuListItemProps> = ({
  text,
  navigateTo,
  disabled,
  tooltipText,
  tick,
  end,
  isAvailable,
}) => {
  const { pathname } = useLocation()

  return (
    <Tooltip
      key={text}
      title={tooltipText}
      arrow
      placement="right"
      color={disabled ? "disabled" : "dark"}
    >
      <span>
        <StyledSubmenuItem
          disabled={disabled}
          component={NavLink}
          to={navigateTo}
          end={end}
        >
          <ListItemText
            primary={text}
            sx={{ ml: 1.5, overflow: "hidden", flex: "unset" }}
          />

          {isAvailable === false ? (
            <Box
              ml={1.5} mr="auto"
              display="flex"
              alignItems="center"
            >
              <RiLockLine size={13} />
            </Box>
          ) : null}

          {tick && navigateTo === pathname
            ? (
              <StyledListItemIcon>
                <SvgIcon name="check" />
              </StyledListItemIcon>
            )
            : null
          }
        </StyledSubmenuItem>
      </span>
    </Tooltip>

  )
}