import { RouteConfig } from "../../App/Routes/routes.config";
import type { ScenarioSimpleResponseModel } from "../../../store/scenarios/scenarios.types";
import type { SubmenuItemsConfigType } from "../SubmenuList/SubmenuList.types";

export const getToolsSubmenuItemsConfig = (scenarios: ScenarioSimpleResponseModel[]) => scenarios.map(
  ({ name, scenarioId, isAvailable }): SubmenuItemsConfigType => ({
    text: name,
    navigateTo: RouteConfig.SCENARIOS.fullPath.replace(":scenarioId?", scenarioId.toString()),
    disabled: false,
    isAvailable
  })
)