import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

import { CheckboxGroup } from "../../../../../../../../components/common/CheckboxGroup/CheckboxGroup.component";
import { OverrideDataConfirmationModal } from "../OverrideDataConfirmationModal/OverrideDataConfirmationModal.component";
import type { QuestionnaireCheckboxGroupProps } from "./QuestionnaireCheckboxGroup.types";

import { useQuestionnaireCheckboxGroup } from "./QuestionnaireCheckboxGroup.hooks";

export const QuestionnaireCheckboxGroup: FC<QuestionnaireCheckboxGroupProps> = ({ headerId, question, defaultValue, lastModified, questionNumber }) => {
  const { t } = useTranslation()
  const {
    parsedChoices,
    value,
    handleChange,
    isSaving,
    conflictDetected,
    localData,
    handleSync,
    handleOverwrite,
    updateLoading,
    error,
  } = useQuestionnaireCheckboxGroup(headerId, question, lastModified, questionNumber, defaultValue)

  return (
    <Box mb={2}>
      <CheckboxGroup
        options={parsedChoices}
        initialValue={value}
        onChange={handleChange}
        disabled={isSaving}
        error={!!error}
        helperText={error
          ? error
          : isSaving ? t("common:saving") : null
        }
      />

      <OverrideDataConfirmationModal
        open={conflictDetected}
        localData={localData}
        serverData={defaultValue?.values ?? []}
        handleSync={handleSync}
        handleOverwrite={handleOverwrite}
        loading={updateLoading}
      />
    </Box>
  )
}