import type { FC, ReactNode } from "react";
import { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";

import { Checkbox } from "../Checkbox/Checkbox.component";
import { InfoLabel } from "../TextField/InfoLabel/InfoLabel.component";

export type CheckboxGroupOption = {
  value: number | string;
  label: ReactNode;
};

export type CheckboxGroupProps = {
  label?: string;
  options: CheckboxGroupOption[];
  initialValue?: CheckboxGroupOption[];
  onChange?: (selectedValues: CheckboxGroupOption[]) => void;
  error?: boolean;
  helperText?: string | null;
  disabled?: boolean;
};

export const CheckboxGroup: FC<CheckboxGroupProps> = ({
  label,
  options,
  initialValue = [],
  onChange,
  error,
  helperText,
  disabled,
}) => {
  const [checkboxValues, setCheckboxValues] = useState<CheckboxGroupOption[]>(initialValue);

  useEffect(() => {
    setCheckboxValues(initialValue);
  }, [initialValue]);

  const handleChange = (option: CheckboxGroupOption) => {
    let newArray = [...checkboxValues];

    const index = newArray.findIndex(({ value }) => value === option.value);
    if (index === -1) {
      newArray = [option, ...newArray];
    } else {
      newArray.splice(index, 1);
    }

    setCheckboxValues(newArray);
    onChange?.(newArray);
  };

  return (
    <FormControl component="fieldset" fullWidth>
      {label ? (
        <FormLabel component="legend" sx={{ mb: 1.5 }}>
          <Typography variant="body2" fontWeight={600}>
            {label}
          </Typography>
        </FormLabel>
      ) : null}

      <FormGroup>
        {options.map((option) => (
          <FormControlLabel
            disabled={disabled}
            sx={{
              "& .MuiFormControlLabel-root": {
                ml: -0.5,
                mr: 0,
              }
            }}
            label={
              typeof option.label === "string" ? (
                <Typography variant="body1">{option.label}</Typography>
              ) : (
                option.label
              )
            }
            key={option.value}
            componentsProps={{ typography: { width: "100%" } }}
            control={
              <Checkbox
                value={checkboxValues.some((checked) => checked.value === option.value)}
                onChange={() => handleChange(option)}
                disabled={disabled}
              />
            }
          />
        ))}
      </FormGroup>
      <FormHelperText>
        <InfoLabel invalid={error}>{helperText}</InfoLabel>
      </FormHelperText>
    </FormControl>
  );
};