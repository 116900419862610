import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { AxiosError } from "axios";
import { HttpStatusCode } from "axios";
import { useSnackbar } from "notistack";

import { useAddQuestionnaireAnswerMutation, useUpdateQuestionnaireAnswerMutation } from "../../../../../../../../store/reportAnswers/reportAnswers.api";
import type { AutocompleteOption } from "../../../../../../../../components/common/Autocomplete/Autocomplete.types";
import type { ReportAnswerSimpleResponseModel } from "../../../../../../../../store/reportAnswers/reportAnswers.types";
import type { ReportQuestionSimpleResponseModel } from "../../../../../../../../store/reportQuestionSections/reportQuestionSections.types";
import type { ApiErrorResponseModel } from "../../../../../../../../store/store.types";

export const useQuestionnaireAutocomplete = (
  headerId: number,
  question: ReportQuestionSimpleResponseModel,
  lastModified: string,
  questionNumber: string,
  defaultValue?: ReportAnswerSimpleResponseModel,
) => {
  const { t } = useTranslation()

  const parsedChoices = useMemo(() => question.choices?.map(choice => ({
    value: choice.value,
    label: choice.displayString
  })), [question.choices])

  const defaultSetOption = useMemo(() => parsedChoices?.find(choice => choice.value === defaultValue?.values[0]), [parsedChoices, defaultValue])

  const prevValueRef = useRef<string | null>(null);
  const [localData, setLocalData] = useState<AutocompleteOption<string> | null>(defaultSetOption ?? null);
  const [conflictDetected, setConflictDetected] = useState(false);
  const [error, setError] = useState<string | null>(null)

  const [postAnswer, { isLoading: postLoading }] = useAddQuestionnaireAnswerMutation()
  const [updateAnswer, { isLoading: updateLoading }] = useUpdateQuestionnaireAnswerMutation()
  const { enqueueSnackbar } = useSnackbar()

  const handleSave = async (newValue: AutocompleteOption<string>) => {
    try {
      if (defaultValue) {
        await updateAnswer({
          headerId,
          lastModified,
          data: {
            values: [newValue.value],
            reportAnswerId: defaultValue.reportAnswerId,
          },
        }).unwrap()
      } else {
        await postAnswer({
          data: {
            values: [newValue.value],
            reportQuestionId: question.reportQuestionId,
            reportHeaderId: headerId,
          }
        }).unwrap()
      }
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
      } else {
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  const handleChange = (newValue: AutocompleteOption<string>) => {

    setLocalData(newValue);
    handleSave(newValue)
    if (error) setError(null);
  };

  const handleSync = () => {
    setLocalData(defaultSetOption ?? null);
    setConflictDetected(false)
  }

  const handleOverwrite = async () => {
    try {
      await updateAnswer({
        headerId,
        lastModified,
        data: {
          values: [localData?.value as string],
          reportAnswerId: defaultValue?.reportAnswerId as number,
        }
      }).unwrap()

      setConflictDetected(false);
    } catch (e) {
      const err = e as AxiosError<ApiErrorResponseModel>

      if (err.status === HttpStatusCode.PreconditionFailed) {
        setConflictDetected(true)
        enqueueSnackbar(t("errors:alreadyUpdated"), { variant: "warning" })
      } else {
        setConflictDetected(false);
        enqueueSnackbar(t("errors:globalError"), { variant: "error" })
      }
    }
  }

  useEffect(() => {
    if (defaultValue?.values[0] !== prevValueRef.current) {
      setLocalData(defaultSetOption ?? null)
      if (localData?.value !== defaultValue?.values[0] && !!localData?.value && !conflictDetected) {
        enqueueSnackbar(t("reports:questionnaireStep:questionHasBeenUpdated", { questionNumber }), { variant: "warning" })
      }

      prevValueRef.current = defaultValue?.values[0] ?? null;
    }
  }, [defaultSetOption]);

  return {
    conflictDetected,
    handleSync,
    handleOverwrite,
    parsedChoices,
    localData,
    handleChange,
    isLoading: postLoading || updateLoading
  }
}