import { apiService } from "../store.service";
import type { LoginResponseModel } from "./auth.types";

import { logInByTokenConfig, logInConfig } from "./auth.config";
import { clearSession, setAccessToken, setDataLoaded, setUserData } from "./auth.slice";

export const AuthApi = apiService
  .enhanceEndpoints({
    addTagTypes: ["UserSettings"]
  })
  .injectEndpoints({
    endpoints: build => ({
      logIn: build.mutation<LoginResponseModel, { username: string, password: string }>({
        query: ({ username, password }) => ({
          ...logInConfig,
          data: {
            username,
            password
          }
        }),
        onQueryStarted: async ({ username, password }, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled
            const userData = response.data
            const token = window.btoa(`${username}:${password}`)

            dispatch(setUserData(userData))
            dispatch(setAccessToken(token))
            localStorage.setItem("token", token);
            dispatch(setDataLoaded(true))
          } catch (error) { } // TODO: handle error
        },
      }),
      logInByToken: build.query<LoginResponseModel, void>({
        query: () => ({
          ...logInByTokenConfig,
        }),
        onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
          try {
            const response = await queryFulfilled
            const userData = response.data

            dispatch(setUserData(userData))
            dispatch(setDataLoaded(true))
          } catch (error) {
            dispatch(clearSession())
          }
        },
        providesTags: ["UserSettings"]
      }),
    }),
  });

export const {
  useLogInMutation,
  useLogInByTokenQuery,
  useLazyLogInByTokenQuery
} = AuthApi