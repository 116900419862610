import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

import { useModal } from "../../hooks/useModal";
import SvgIcon from "../common/SvgIcon/SvgIcon.component";
import { ContactFormModal } from "../ContactFormModal/ContactFormModal.component";

export const FeatureNotAvailable: FC = () => {
  const { t } = useTranslation()
  const { isOpen, open, close } = useModal()

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      my={10}
    >
      <SvgIcon name="featureNotAvailable" />

      <Box display="flex" alignItems="center" mt={3}>
        <Typography
          variant="body1"
          fontWeight={500}
          color="text.secondary"
          textAlign="center"
        >
          {t("common:featureNotAvailable")}&nbsp;
        </Typography>

        <Typography
          variant="body1"
          fontWeight={500}
          color="primary.main"
          textAlign="center"
          onClick={open}
          sx={{ cursor: "pointer" }}
        >
          {t("common:contactUsToUpgradePlan")}
        </Typography>
      </Box>

      <ContactFormModal
        open={isOpen}
        onClose={close}
      />
    </Box>
  )
}
