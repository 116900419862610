import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useOutletContext } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";

import { useGetScenarioByIdQuery } from "../../../../store/scenarios/scenarios.api";
import { Button } from "../../../../components/common/Button/Button.component";
import { Loader } from "../../../../components/common/Loader/Loader.component";

export const ScenarioTab: FC = () => {
  const { t } = useTranslation()
  const { scenarioId } = useOutletContext<{ scenarioId: number }>()

  const { data: scenario, isLoading } = useGetScenarioByIdQuery({ scenarioId }, { refetchOnMountOrArgChange: true })

  if (isLoading) {
    return <Loader />
  }

  return (
    <Grid
      spacing={3}
      container
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Paper>
          <Typography
            variant="h1"
            color="text.dark"
            fontWeight={500}
          >
            {scenario?.name}
          </Typography>

          {scenario?.description ? (
            <Typography
              variant="body1"
              color="text.secondary"
              mt={1}
            >
              {scenario.description}
            </Typography>
          ) : null}

          <Button
            size="small"
            component={NavLink}
            to="calculator"
            sx={{ mt: 3 }}
            disabled={!scenario?.isAvailable}
          >
            {t("common:getStarted")}
          </Button>
        </Paper>
      </Grid>
    </Grid>
  )
}
