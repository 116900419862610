import type { RouteConfig } from "../../api/rest/api"

// Load all available answers for the requested page.
export const getQuestionnaireAnswersConfig = (headerId: number): RouteConfig => ({
  method: "GET",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers`,
  // token: required
})

export const addQuestionnaireAnswerConfig = (headerId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers`,
  // token: required
})

export const updateQuestionnaireAnswerConfig = (headerId: number, answerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers/${answerId}`,
  // token: required
})

export const deleteQuestionnaireAnswerConfig = (headerId: number, answerId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers/${answerId}`,
  // token: required
})

export const updateQuestionFileConfig = (headerId: number, answerId: number): RouteConfig => ({
  method: "PUT",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers/${answerId}/Files`,
  headers: {
    "Content-Type": "multipart/form-data"
  }
  // token: required
})

export const postQuestionFileConfig = (headerId: number, questionId: number): RouteConfig => ({
  method: "POST",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Questions/${questionId}/Files`,
  headers: {
    "Content-Type": "multipart/form-data"
  }
  // token: required
})

export const deleteQuestionFileConfig = (headerId: number, answerId: number): RouteConfig => ({
  method: "DELETE",
  url: `/api/Reports/Headers/${headerId}/Questionnaires/Answers/${answerId}/Files`,
  // token: required
})