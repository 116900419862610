import { apiService } from "../store.service";
import type { ReportQuestionPageSimpleResponseModel } from "./reportQuestionPages.types";

import { getQuestionnairePagesConfig } from "./reportQuestionPages.config";

export const ReportQuestionnaireSectionsApi = apiService.injectEndpoints({
  endpoints: build => ({
    getQuestionnairePages: build.query<ReportQuestionPageSimpleResponseModel[], { headerId: number }>({
      query: ({ headerId }) => ({
        ...getQuestionnairePagesConfig(headerId),
      }),
    }),
  }),
});

export const {
  useGetQuestionnairePagesQuery,
} = ReportQuestionnaireSectionsApi